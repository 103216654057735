import React from 'react'
import { useDispatch } from 'react-redux'

import { setBlockLevelData } from '@store/actionSlices/blockLevel'
import { ProjectIdentity } from '@store/types'

import {
  selectFromResult as selectBuildingFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

interface BlockLevelProps {
  projectIdentity: ProjectIdentity
}

const useBlockLevelData = ({ projectIdentity }: BlockLevelProps) => {
  const dispatch = useDispatch()

  const {
    maps: interactivePlanMap,
    isLoaded: isInteractivePlanLoaded,
    isError: hasInteractivePlanError,
  } = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectInteractiveFromResult }
  )

  const {
    levels: buildingLevels,
    blockOrders: buildingBlockOrders,
    isLoaded: isBuildingLoaded,
    isError: hasBuildingError,
  } = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectBuildingFromResult }
  )

  const sortedObject = React.useMemo(
    () =>
      buildingBlockOrders.length > 1
        ? Object.fromEntries(
            buildingBlockOrders?.map((key) => [
              key,
              interactivePlanMap.blocks?.[key],
            ])
          )
        : interactivePlanMap.blocks,
    [
      isBuildingLoaded,
      hasBuildingError,
      isInteractivePlanLoaded,
      hasInteractivePlanError,
    ]
  )

  React.useEffect(() => {
    if (
      (isInteractivePlanLoaded && !hasInteractivePlanError) ||
      buildingBlockOrders.length > 1
    ) {
      dispatch(
        setBlockLevelData({
          blocks: sortedObject,
        })
      )
    }
  }, [isInteractivePlanLoaded, sortedObject])

  React.useEffect(() => {
    if (isBuildingLoaded && !hasBuildingError) {
      dispatch(
        setBlockLevelData({
          levels: buildingLevels,
        })
      )
    }
  }, [isBuildingLoaded, buildingLevels])
}

export default useBlockLevelData
