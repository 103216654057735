import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface ProjectListDetails {
  id: string
  title: string
  titleCanonical: string
}

export interface Pagination {
  page: number
  items: number
  total: number
}

export interface ProjectDetails {
  lists: Array<ProjectListDetails>
  meta: {
    pagination: Pagination
  }
}

type ProjectListType = {
  email: string
  page: number
  query: string
}

export const projectListApi = createApi({
  reducerPath: 'projectListApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getProjectList: builder.query<ApiResponse<ProjectDetails>, ProjectListType>(
      {
        query: (params: ProjectListType) => ({
          url: `/v1/project/lists/${params.email}`,
          method: 'get',
          params: {
            page: params.page,
            show: 5,
            archived: false,
            showcaseActive: true,
            query: params.query,
          },
        }),
      }
    ),
  }),
})

export const selectFromResult = ({
  status,
  data,
  error,
}: UseQueryStateResult<any, any>) => ({
  list: data?.data?.lists || [],
  pagination: data?.data?.meta?.pagination || ({} as Pagination),
  isLoaded: status === 'fulfilled',
  hasError: error !== undefined,
  status,
})

export const { useGetProjectListQuery, useLazyGetProjectListQuery } =
  projectListApi
