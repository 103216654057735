import React from 'react'

import { SvgProps } from './types'

const FrameRefreshSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
  rotate,
  stroke,
  strokeColor,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 2.0,
    lg: 1.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          height: 'auto',
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || 24}
      height={height || 24}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || 'none'}
      viewBox="0 0 24 25"
      stroke={strokeColor || 'currentColor'}
      strokeWidth={stroke || '1'}
    >
      <path
        d="M11 21.5H4C3.46957 21.5 2.96086 21.2893 2.58579 20.9142C2.21071 20.5391 2 20.0304 2 19.5V5.5C2 4.96957 2.21071 4.46086 2.58579 4.08579C2.96086 3.71071 3.46957 3.5 4 3.5H20C20.5304 3.5 21.0391 3.71071 21.4142 4.08579C21.7893 4.46086 22 4.96957 22 5.5V12.5"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 7.5H22M5 5.51L5.01 5.499M8 5.51L8.01 5.499M11 5.51L11.01 5.499M21.666 17.167C21.049 15.597 19.636 14.5 17.99 14.5C16.232 14.5 14.738 15.755 14.197 17.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.995 17.272H21.4C21.4788 17.272 21.5568 17.2565 21.6296 17.2263C21.7024 17.1962 21.7685 17.152 21.8243 17.0963C21.88 17.0405 21.9242 16.9744 21.9543 16.9016C21.9845 16.8288 22 16.7508 22 16.672V15.05M14.334 19.833C14.953 21.403 16.366 22.5 18.01 22.5C19.768 22.5 21.262 21.245 21.803 19.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.005 19.728H14.6C14.4409 19.728 14.2883 19.7912 14.1757 19.9037C14.0632 20.0163 14 20.1689 14 20.328V21.95"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FrameRefreshSvg
