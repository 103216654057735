import React from 'react'
import { useDispatch } from 'react-redux'

import { setRemoteRefreshTriggerKey } from '@store/actionSlices/appConfig'

interface UtilityPropsInterface {
  refreshTriggerKeyFirebase: string
  refreshTriggerKeyLocal: string
}

const RefreshHandler = ({
  refreshTriggerKeyFirebase,
  refreshTriggerKeyLocal,
}: UtilityPropsInterface): void => {
  const dispatch = useDispatch()

  const refresh = (): void => window.location.reload()

  const refreshAppOnRefreshTriggerKeyChange = React.useCallback(() => {
    if (refreshTriggerKeyFirebase !== refreshTriggerKeyLocal) {
      dispatch(setRemoteRefreshTriggerKey(refreshTriggerKeyFirebase))
      refresh()
    }
  }, [refreshTriggerKeyFirebase, refreshTriggerKeyLocal])

  React.useEffect(() => {
    if (refreshTriggerKeyFirebase) {
      refreshAppOnRefreshTriggerKeyChange()
    }
  }, [refreshTriggerKeyFirebase])
}

export default RefreshHandler
