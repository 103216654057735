import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface Configuration {
  bed: string | number
  study: string | number
  bath: string | number
  powderRoom: string | number
  car: string | number
}

export interface ViewLineImage {
  id: string
  src: string
  label: string
}

export interface Metas {
  internalArea: number
  externalArea: number
  totalArea: number
  aspect: string
  aspectShort: string
  viewImage: Array<string>
  viewLineImages: Array<ViewLineImage> | null
  floorImage: string
  floorPdf: string
  level: string
  classification: string
  configurations: Configuration
  notes: string
  projectUrl: string
  bedConfig: string
  price: string
  pricem2: string
  status: string
  class: string
  statusName: string
  productShort: string
}

export interface Unit {
  id: string
  order: string
  aspect: string
  agentDimmed: boolean
  area: number
  name: string
  width: number
  metas: Metas
  blockId: string
  extras: any
}

export interface Level {
  level: string
  data: Array<Unit>
  height: number
}

type BuildingType = {
  projectName: string
}

export const buildingApi = createApi({
  reducerPath: 'buildingApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBuilding: builder.query<ApiResponse<Array<Level>>, BuildingType>({
      query: (params: BuildingType) => ({
        url: `/v1/${params.projectName}/building`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  isSuccess,
  error,
}: UseQueryStateResult<any, any>): {
  levels: Level[]
  blockOrders: string[]
  isLoaded: boolean
  isError: boolean
  status: string
  isSuccess: boolean
  errorStatus: number
} => ({
  levels: data?.data?.lists || [],
  blockOrders: data?.data?.blockOrders || [],
  isLoaded: !isLoading,
  isError,
  isSuccess,
  errorStatus: error?.status,
  status,
})

export const { useGetBuildingQuery } = buildingApi
