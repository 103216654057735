import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryItemInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface TeamInfoInterface {
  id: string
  label: string
  name: string
  imageSource: string
  imageName: string
  description: string
  type: string
}

type TeamTypeInterface = {
  projectName: string
}

export const teamApi = createApi({
  reducerPath: 'teamApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getTeam: builder.query<
      ApiResponse<Array<TeamInfoInterface>>,
      TeamTypeInterface
    >({
      query: (params: TeamTypeInterface) => ({
        url: `/v1/${params.projectName}/teams${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processGalleryData = (
  data: Array<TeamInfoInterface>
): Array<GalleryItemInterface> => {
  const items: Array<GalleryItemInterface> = []
  data.forEach((teamData: TeamInfoInterface) => {
    if (teamData.type === 'logo') {
      items.push({
        id: teamData.id,
        title: teamData.label || teamData.imageName || 'Untitled',
        imageSource: teamData.imageSource,
      })
    }
  })
  return items
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  teamData: GalleryItemInterface[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  teamData: processGalleryData(data?.data.teamsInfo || []),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetTeamQuery } = teamApi
