import React from 'react'
import { useHistory } from 'react-router-dom'

import { ProjectIdentity, SessionMap } from '@store/types'

import { Modal } from '@components/modals'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId } from '@utilities/firebase-util'

import { ArrowRefreshSvg, FrameRefreshSvg } from '@svg/react'

export interface RefreshSessionHandlerProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const RefreshSessionHandler = ({
  projectIdentity,
  session,
}: RefreshSessionHandlerProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const history = useHistory()
  const [isModalActive, modalToggle] = React.useState(false)

  const [refreshTriggerKeyRestTimerId, setRefreshTriggerKeyRestTimerId] =
    React.useState<ReturnType<typeof setTimeout>>()

  const [isRefreshing, setRefreshState] = React.useState(false)

  const setRefreshTriggerKeyOnFirebase = (state: string, key = ''): void => {
    firebaseControlQuery.update({
      [`clearCacheTriggerKey`]: key,
      [`clearCacheKeyState`]: state,
    })
  }

  const handleRefreshClick = () => {
    setRefreshState(true)
    setRefreshTriggerKeyOnFirebase('registered', generateId(10))
  }

  const resetRefreshTriggerKeyTimer = () => {
    setRefreshState(false)
    if (refreshTriggerKeyRestTimerId) {
      clearTimeout(refreshTriggerKeyRestTimerId)
      setRefreshTriggerKeyRestTimerId(undefined)
    }
  }

  const resetRefreshKeyStateAndReload = async (state?: string) => {
    if (state !== 'cleared') return

    await firebaseControlQuery
      .update({
        [`clearCacheKeyState`]: '',
        [`activeRoute`]: 'vision',
      })
      .then(() => {
        modalToggle(false)
        history.push('vision')
        window.location.reload()
      })
  }

  const handleRefreshTriggerKeyChange = async (
    refreshTriggerKey?: string,
    myRefreshKeyState?: string
  ) => {
    if (!refreshTriggerKey) {
      resetRefreshTriggerKeyTimer()
      resetRefreshKeyStateAndReload(myRefreshKeyState)
      return
    }

    if (!refreshTriggerKeyRestTimerId) {
      setRefreshTriggerKeyRestTimerId(
        setTimeout(() => {
          setRefreshTriggerKeyOnFirebase('cleared', '')
        }, 30 * 1000)
      )
    }
  }

  React.useEffect(() => {
    if (session) {
      const {
        clearCacheTriggerKey: firebaseRefreshTriggerKey,
        clearCacheKeyState: firebaseRefreshKeyState,
      } = session
      handleRefreshTriggerKeyChange(
        firebaseRefreshTriggerKey,
        firebaseRefreshKeyState
      )
    }
  }, [session])

  return (
    <>
      <Modal
        toggleModal={(res) => modalToggle(res)}
        handleApply={() => handleRefreshClick()}
        isVisible={isModalActive}
        title="Refresh session"
        applyButtonText="Cancel"
        theme={{
          textColor: 'text-mainColour',
          mainBg: 'bg-mainColour',
          secondaryBg: 'bg-neutralColour',
        }}
        noMainControls
      >
        <div className="p-5 text-default text-mainColour">
          Click below to Refresh your Showcase remote session.
        </div>
        <div className="flex items-center justify-between gap-4  border-gray-200 pt-3">
          <button
            type="button"
            disabled={isRefreshing}
            onClick={() => modalToggle(false)}
            className="w-full rounded-lg border bg-neutralColour py-5 text-subHeading font-normal text-mainColour"
          >
            Close
          </button>
          <button
            disabled={isRefreshing}
            type="button"
            onClick={() => handleRefreshClick()}
            className="flex w-full items-center justify-center rounded-lg bg-gradient py-5 text-subHeading font-normal text-white"
          >
            Refresh
            {isRefreshing && (
              <ArrowRefreshSvg
                className="ml-2 animate-spin text-white"
                size="lg"
              />
            )}
          </button>
        </div>
      </Modal>
      <button
        onClick={() => modalToggle(true)}
        type="button"
        className="ml-auto flex items-center text-default text-neutralColour"
      >
        Refresh Session
        <FrameRefreshSvg className="ml-3 text-neutralColour" size="lg" />
      </button>
    </>
  )
}

export default RefreshSessionHandler
