import React from 'react'
import { createPortal } from 'react-dom'

export interface ComponentInterface {
  isVisible: boolean
  toggleModal: (arg: boolean) => void
  message?: string
  title?: string
  applyButtonText?: string
  closeButtonText?: string
  handleClose?: () => void
  handleApply?: () => void
  children?: React.ReactNode
  modalWidth?: string
  noMainControls?: boolean
  hasHeaderControl?: boolean
  theme?: {
    textColor: string
    mainBg: string
    secondaryBg: string
    closeTextColor?: string
  }
  applyDisabled?: boolean
  cancelDisabled?: boolean
  titleClassName?: string
  footerClassName?: string
  cancelClassName?: string
  okayClassName?: string
}

const Modal = ({
  isVisible = false,
  toggleModal,
  message = '',
  title = '',
  applyButtonText = '',
  closeButtonText = 'Close',
  children,
  modalWidth,
  handleClose,
  handleApply,
  theme,
  noMainControls,
  hasHeaderControl,
  applyDisabled = false,
  cancelDisabled = false,
  titleClassName,
  footerClassName,
  cancelClassName,
  okayClassName,
}: ComponentInterface) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      containerRef.current &&
      containerRef.current === (event.target as Node)
    ) {
      if (isVisible) {
        toggleModal(false)
      }
    }
  }

  const setCloseButtonTheme = () => {
    if (applyButtonText) {
      return `border-gray-200 ${theme?.secondaryBg || 'bg-white'} ${
        theme?.closeTextColor || theme?.textColor || 'text-black'
      }`
    }
    return `${theme?.mainBg || 'bg-black'} font-semibold text-white`
  }

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isVisible])

  return createPortal(
    <div
      aria-hidden="true"
      className={`fixed inset-0 z-20 h-full items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-400 bg-opacity-10 transition-all duration-500
     ${isVisible ? 'visible opacity-100' : 'invisible opacity-0'}
    `}
    >
      <div
        className="flex h-screen items-center text-default font-medium"
        ref={containerRef}
      >
        <div
          className={`relative m-auto h-auto w-full rounded-lg p-7 shadow ${
            theme?.secondaryBg || 'bg-white'
          } ${modalWidth || 'max-w-lg'}`}
        >
          <div>
            <h3
              className={
                titleClassName ??
                `text-center text-heading leading-[120%] ${
                  theme?.textColor || 'text-fontBlackColour'
                }`
              }
            >
              {title}
              {hasHeaderControl && (
                <button
                  type="button"
                  onClick={() => {
                    if (handleClose) {
                      handleClose()
                    } else {
                      toggleModal(false)
                    }
                  }}
                  className="absolute right-0 ml-auto pr-7 text-default font-normal text-grayColour"
                >
                  Cancel
                </button>
              )}
            </h3>
            <div className="text-center">
              {message ? (
                <p
                  className={`leading-[120%] opacity-60 ${
                    theme?.textColor || 'text-fontBlackColour'
                  }`}
                >
                  {message}
                </p>
              ) : (
                children
              )}
            </div>
          </div>
          {!noMainControls && (
            <div
              className={
                footerClassName ??
                'mt-5 flex items-center justify-between  gap-[15px] border-gray-200'
              }
            >
              <button
                type="button"
                disabled={cancelDisabled}
                onClick={() => {
                  if (handleClose) {
                    handleClose()
                  } else {
                    toggleModal(false)
                  }
                }}
                className={
                  cancelClassName ??
                  `w-full rounded-lg border py-5 text-subHeading font-normal ${setCloseButtonTheme()}`
                }
              >
                {closeButtonText}
              </button>
              {applyButtonText && (
                <button
                  disabled={applyDisabled}
                  type="button"
                  onClick={() => {
                    if (handleApply) {
                      handleApply()
                    }
                  }}
                  className={
                    okayClassName ??
                    `w-full rounded-lg py-5 text-subHeading font-normal text-white ${
                      theme?.mainBg || 'bg-black'
                    }`
                  }
                >
                  {applyButtonText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('root') as Element
  )
}

export default Modal
