import React from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { ArrowTurnLeftSvg } from '@src/components/adgroup-svg/react'
import ArrowLeftRightSvg from '@src/components/adgroup-svg/react/arrows-right-left-svg'
import Throttle from '@src/utilities/throttle'

import {
  ProjectIdentity,
  ProjectType,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import PannellumView from '@components/showcase-pannellum'
import {
  PannellumDataInterface,
  PannellumViewRefInterface,
  PanoramaImageType,
} from '@components/showcase-pannellum/types'

import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { getQueryStringParams } from '@utilities/helper'

import PanoramicSkeleton from './panoramic-skeleton'

interface PanoramicProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const Panoramic = ({ session, projectIdentity }: PanoramicProps) => {
  const history = useHistory()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const location = useLocation()
  const { prevUrl } = getQueryStringParams(location.search)

  const pannellumViewRef = React.useRef<PannellumViewRefInterface>()

  const [currentZoom, setCurrentZoom] = React.useState(0)
  const [currentYaw, setCurrentYaw] = React.useState(0)
  const [currentPitch, setCurrentPitch] = React.useState(0)

  const [panoramic, setPanoramic] = React.useState<PannellumDataInterface[]>([])

  const [rotationSpeed, setRotationSpeed] = React.useState(0)

  const [scene, setScene] = React.useState('')
  const [activeSceneId, setActiveSceneId] = React.useState('')
  const [activePanoramaType, setActivePanoramaType] =
    React.useState<PanoramaImageType>(PanoramaImageType.Sphere)

  const [viewerInit, setViewerInit] = React.useState(false)
  const [eventsInit, setEventsInit] = React.useState(false)

  const zoom = Throttle(currentZoom, 500)
  const pitch = Throttle(currentPitch, 500)
  const yaw = Throttle(currentYaw, 500)

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromPanoramicResult }
  )

  React.useEffect(() => {
    if (panoramic.length > 0) {
      const activeItem = panoramic.find(
        (res) => res.panoramaGroup === scene || res.nameMap === scene
      )
      setActiveSceneId(activeItem?.nameMap || panoramic[0]?.nameMap)
      setActivePanoramaType(
        activeItem?.panoramaType ||
          panoramic[0]?.panoramaType ||
          PanoramaImageType.Sphere
      )
    }
  }, [scene, panoramic])

  React.useEffect(() => {
    if (panoramicPayload.panoramicData.length > 0 && panoramic.length === 0) {
      setPanoramic(panoramicPayload.panoramicData)
    }
  }, [panoramicPayload])

  const handleSceneChange = (e: string) => {
    setRotationSpeed(0)
    setCurrentYaw(pannellumViewRef?.current?.pannellum.getYaw() || 0)
    setCurrentPitch(pannellumViewRef?.current?.pannellum.getPitch() || 0)
    setCurrentZoom(pannellumViewRef?.current?.pannellum.getHfov() || 0)
    setScene(e)
  }

  const resetYawAndPitch = () => {
    setRotationSpeed(0)
    pannellumViewRef?.current?.pannellum.setYaw(0)
    pannellumViewRef?.current?.pannellum.setPitch(0)
  }

  const handleSwipe = () => {
    setRotationSpeed(0)
    setCurrentYaw(pannellumViewRef?.current?.pannellum.getYaw() || 0)
    setCurrentPitch(pannellumViewRef?.current?.pannellum.getPitch() || 0)
  }

  const handleZoomChange = () => {
    setRotationSpeed(0)
    setCurrentZoom(pannellumViewRef?.current?.pannellum.getHfov() || 0)
  }

  React.useEffect(() => {
    if (pannellumViewRef?.current && viewerInit) {
      pannellumViewRef?.current.pannellum.on(
        'mousedown',
        () => handleZoomChange
      )
      pannellumViewRef?.current.pannellum.on('scenechange', handleSceneChange)
      pannellumViewRef?.current.pannellum.on('zoomchange', handleZoomChange)
      setEventsInit(true)
    }
    return () => {
      if (pannellumViewRef?.current && viewerInit) {
        if (eventsInit) {
          pannellumViewRef?.current.pannellum.off(
            'scenechange',
            handleSceneChange
          )
          pannellumViewRef?.current.pannellum.off(
            'zoomchange',
            handleZoomChange
          )
        }
      }
    }
  }, [viewerInit, eventsInit, activeSceneId])

  React.useEffect(() => {
    if (pannellumViewRef?.current?.pannellum) {
      firebaseControlQuery.update({
        [`panoramic.rotationSpeed`]: rotationSpeed,
      })
    }
  }, [rotationSpeed])

  React.useEffect(() => {
    if (pannellumViewRef?.current?.pannellum) {
      firebaseControlQuery.update({
        [`panoramic.pitch`]: pitch,
        [`panoramic.yaw`]: yaw,
        [`panoramic.zoom`]: zoom,
        [`panoramic.scene`]: activeSceneId,
      })
    }
  }, [pitch, yaw, zoom, activeSceneId])

  const handleAutoRotate = () => {
    if (rotationSpeed === 0) {
      setRotationSpeed(2)
      return
    }
    resetYawAndPitch()
  }

  const returnPrevPage = () => {
    if (prevUrl) {
      history.push(prevUrl)
      firebaseControlQuery.updateRoute(
        prevUrl === 'lot-view' ? 'stages' : prevUrl
      )
      return
    }

    if (projectIdentity.type === ProjectType.HouseAndLand) {
      history.push('stages')
      firebaseControlQuery.updateRoute('stages')
      return
    }

    firebaseControlQuery.updateRoute('building')
  }

  React.useEffect(() => {
    if (session) {
      const {
        building: {
          activeUnit: firebaseActiveUnit,
          activeLevel: firebaseActiveLevel,
        },
        houseAndLand: {
          activeStageId: firebaseActiveStageId,
          activeLotId: firebaseActiveLotId,
        },
      } = session

      if (scene) {
        return
      }

      if (projectIdentity?.type === ProjectType.HouseAndLand) {
        setScene(firebaseActiveLotId || firebaseActiveStageId)
        return
      }

      setScene(firebaseActiveUnit || firebaseActiveLevel)
    }
  }, [
    session?.building?.activeUnit,
    session?.building?.activeLevel,
    scene,
    projectIdentity?.type,
  ])

  return (
    <Container>
      <DataHandler
        payload={{
          ...panoramicPayload,
          data: panoramicPayload.panoramicData,
        }}
        skeletonFrame={<PanoramicSkeleton />}
      >
        <div className="absolute left-0 top-0 z-50 w-full  px-5 py-5">
          <button
            type="button"
            onClick={returnPrevPage}
            className="rounded border-2 border-solid border-tertiaryColour bg-neutralColour p-2 text-tertiaryColour shadow-lg"
          >
            <ArrowTurnLeftSvg height="48" width="48" />
          </button>
          <button
            type="button"
            onClick={handleAutoRotate}
            className=" ml-5  rounded border-2 border-solid  border-tertiaryColour bg-neutralColour p-2 text-tertiaryColour shadow-lg"
          >
            <ArrowLeftRightSvg />
          </button>
        </div>

        <PannellumView
          key="panoramic-view"
          ref={pannellumViewRef}
          payload={panoramic}
          activeScene={activeSceneId}
          panoramaType={activePanoramaType}
          className="h-full w-full"
          viewerInit={setViewerInit}
          swipeHandler={() => handleSwipe()}
          useControlImg
        />
      </DataHandler>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Panoramic)
