import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface BedConfig {
  src: string
  configuration: string
  shortConfiguration: string
  totalUnits: number
  availableUnits: number
  activeLevel: string
  activeUnit: string
  activeBlock: string
}

type BedConfigType = {
  projectName: string
}

export const bedConfigApi = createApi({
  reducerPath: 'bedConfigApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBedConfig: builder.query<ApiResponse<Array<BedConfig>>, BedConfigType>({
      query: (params: BedConfigType) => ({
        url: `/v1/${params.projectName}/beds`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
}: UseQueryStateResult<any, any>) => ({
  bedConfig: data?.data || [],
  isLoaded: !isLoading,
})

export const { useGetBedConfigQuery } = bedConfigApi
