import React from 'react'
import { useDispatch } from 'react-redux'

import {
  ShortlistState,
  addShortlist,
  filterShortlist,
} from '@store/actionSlices/shortlist'
import {
  ProjectIdentity,
  ShortlistItemInterface,
  ShortlistPropertyType,
} from '@store/types'

import FirebaseControlQuery from './firebase-control-query'

interface ComponentProps {
  property: ShortlistItemInterface
  shortlist: ShortlistState
  setShortlistErrorMessage: (arg: string) => void
  toggleConfirmModal: (arg: boolean) => void
  setRemoveShortlistProperty: (arg: boolean) => void
  projectIdentity: ProjectIdentity
  removeShortlistProperty: boolean
  available: string
}
type ShortlistHandlerReturnType = [boolean, () => void]

const ShortlistHandler = ({
  property,
  shortlist,
  setShortlistErrorMessage,
  setRemoveShortlistProperty,
  projectIdentity,
  toggleConfirmModal,
  removeShortlistProperty,
  available,
}: ComponentProps): ShortlistHandlerReturnType => {
  const STATUS_AVAILABLE = 'available'
  const MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) || 4
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const isShortlisted = React.useMemo(
    () =>
      shortlist.shortlists.some(
        (item) => item.propertyId === property.propertyId
      ),
    [shortlist.shortlists, property]
  )

  const handleShortlistClick = () => {
    if (available !== STATUS_AVAILABLE) {
      if (property.type === ShortlistPropertyType.Unit) {
        setShortlistErrorMessage('This unit is not available.')
      }
      if (property.type === ShortlistPropertyType.Lot) {
        setShortlistErrorMessage('This lot is not available.')
      }
      return
    }
    if (isShortlisted) {
      toggleConfirmModal(true)
      return
    }

    if (shortlist.shortlists.length < MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) {
      dispatch(addShortlist(property))

      const updatedShortlists: ShortlistItemInterface[] = [
        ...shortlist.shortlists,
        property,
      ]

      firebaseControlQuery.update({
        'shortlist.properties': JSON.parse(JSON.stringify(updatedShortlists)),
      })
      return
    }

    if (property.type === ShortlistPropertyType.Unit) {
      setShortlistErrorMessage(
        `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} units.`
      )
    }
    if (property.type === ShortlistPropertyType.Lot) {
      setShortlistErrorMessage(
        `You can shortlist a maximum of ${MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST} lots.`
      )
    }
  }

  React.useEffect(() => {
    if (removeShortlistProperty) {
      const updatedShortlists = shortlist.shortlists.filter(
        (item) => property.propertyId !== item.propertyId
      )
      dispatch(
        filterShortlist({
          propertyId: property.propertyId,
          type: property.type,
        })
      )

      firebaseControlQuery.update({
        'shortlist.properties': JSON.parse(JSON.stringify(updatedShortlists)),
        ...(updatedShortlists.length === 0 && {
          'shortlist.selectedDocumentIds': [],
          'shortlist.selectedAgentId': '',
        }),
      })
      setRemoveShortlistProperty(false)
      toggleConfirmModal(false)
    }
  }, [removeShortlistProperty])

  return [isShortlisted, handleShortlistClick]
}

export default ShortlistHandler
