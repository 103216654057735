import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { resetAppConfig } from '@store/actionSlices/appConfig'
import {
  removeProjectIdentity,
  setProjectIdentity,
} from '@store/actionSlices/projectIdentity'
import { removeShortlist } from '@store/actionSlices/shortlist'
import { removeToken } from '@store/actionSlices/token'
import { removeUser } from '@store/actionSlices/user'
import type { ProjectIdentity, RootStateFirebase } from '@store/types'

import { Modal } from '@components/modals'
import SessionField from '@components/session-field'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getFirebasePort, isValidSessionId } from '@utilities/helper'
import { removeOfflineResponseFromLocalStorage } from '@utilities/offline-handler-util'

import { ArrowSvg, DevelopmentIDSvg, LogoutSvg } from '@svg/react'

import AssetSettings from './asset-settings'
import OfflineMode from './offline-mode'

export interface DashboardProps {
  projectIdentity: ProjectIdentity
}

const Dashboard = ({ projectIdentity }: DashboardProps) => {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [sessionId, setSessionId] = React.useState('')
  const [sessionName, setSessionName] = React.useState('')
  const [formValid, setFromValid] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [showAlertModal, toggleAlertModal] = React.useState(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState('')

  const connectToASession = async () => {
    setIsLoading(true)
    const sessionExist = await firebaseControlQuery.doesSessionExist(
      projectIdentity.sessionId || sessionId,
      projectIdentity.projectId
    )

    if (sessionExist) {
      await firebaseControlQuery.updateConnection(true, sessionId)
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          sessionId: projectIdentity.sessionId || sessionId,
          sessionList: [
            {
              sessionName,
              sessionId: projectIdentity.sessionId || sessionId,
              projectId: projectIdentity.projectId,
              projectName: projectIdentity.projectName,
              projectUUID: projectIdentity.projectUUID,
            },
            ...projectIdentity.sessionList,
          ],
        })
      )
      return
    }
    setIsLoading(false)
    setFromValid(false)
    setAlertModalMessage(
      `This session ID is invalid for ${projectIdentity.projectId}.`
    )
    toggleAlertModal(true)
  }

  const logout = () => {
    const firebasePort = getFirebasePort()

    dispatch(removeToken())
    dispatch(removeProjectIdentity())
    dispatch(removeUser())
    dispatch(resetAppConfig())
    dispatch(removeShortlist())
    removeOfflineResponseFromLocalStorage()

    if (firebasePort !== '') {
      window.location.reload()
    }
  }

  const convertToSessionId = (generateSessionId: string) => {
    setSessionId(generateSessionId.replace(/\s/g, ''))
  }

  React.useEffect(() => {
    if (isValidSessionId(sessionId) || isLoading) {
      setFromValid(true)
      return
    }
    setFromValid(false)
  }, [sessionId, isLoading])

  React.useEffect(() => {
    if (
      !projectIdentity.masterKey &&
      projectIdentity.sessionId &&
      !projectIdentity.sessionList.find(
        (res) => res.sessionId === projectIdentity.sessionId
      )
    ) {
      connectToASession()
    }
  }, [projectIdentity])

  React.useEffect(() => {
    if (projectIdentity.sessionId) {
      setSessionId(projectIdentity.sessionId)
    }
  }, [])

  return (
    <>
      <div className="h-screen w-screen bg-[#171A21]">
        <div className="flex items-center justify-between p-6">
          <DevelopmentIDSvg />

          {projectIdentity.disconnect && (
            <button className="text-white" type="button" onClick={logout}>
              <LogoutSvg />
            </button>
          )}
        </div>
        <div className="no-scrollbar h-login-page-container overflow-auto">
          <div className="mx-auto w-[36rem] p-4 ipad-mini:p-0">
            <div className="sm-h:pt-0 pt-12 text-default font-medium">
              <p className="text-left text-heading text-white">
                Connect to Showcase
              </p>
              <form
                className="mt-[40px] flex flex-col gap-5 text-white"
                onSubmit={(e) => {
                  e.preventDefault()
                  connectToASession()
                }}
              >
                <div>
                  <div className="mb-3 flex text-subHeading">
                    <label htmlFor="sessionId">Session ID</label>
                    <span className="text-[#017EE4]">*</span>
                  </div>
                  <SessionField
                    convertToSessionId={convertToSessionId}
                    sessionId={sessionId}
                    className="h-[121px] text-[82px]"
                  />
                </div>
                <div>
                  <div className="mb-3 flex text-subHeading">
                    <label htmlFor="sessionName">Session Name </label>
                    <span className="text-grayColour">(optional)</span>
                  </div>
                  <input
                    className="w-full rounded-lg bg-grayColour text-default focus:border-transparent focus:bg-white focus:text-fontBlackColour focus:ring-0"
                    id="sessionName"
                    onChange={(e) => {
                      setSessionName(e.target.value)
                    }}
                    type="text"
                  />
                </div>

                <div className="flex items-center justify-center">
                  <button
                    className={`focus:shadow-outline bg-[#017EE4] ${
                      !formValid && 'cursor-not-allowed opacity-30'
                    } sm-h:py-4 relative flex w-full
                    items-center justify-center rounded-lg bg-[#017EE4] py-7 text-heading focus:outline-none
                  ipad-pro-land:p-5
                  `}
                    type="submit"
                    disabled={!formValid || isLoading}
                  >
                    Connect
                    <div
                      className={`absolute ${
                        isLoading ? 'right-8' : 'right-4'
                      }`}
                    >
                      {isLoading ? (
                        <div
                          style={{ borderTopColor: 'transparent' }}
                          className="border-white-400 h-7 w-7 animate-spin rounded-full border-2"
                        ></div>
                      ) : (
                        <ArrowSvg rotate="right" strokeColor="#FFF7E9" />
                      )}
                    </div>
                  </button>
                </div>
              </form>
              <div className="mt-5 flex justify-center gap-[27px]">
                <AssetSettings />
                <OfflineMode />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Error"
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
      />
    </>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(Dashboard)
