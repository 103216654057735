import React from 'react'

import { SvgProps } from './types'

const RewindSvg = ({ width, height, className }: SvgProps) => (
  <svg
    width={width || '15'}
    height={height || '17'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 37 40"
    fill="none"
  >
    <path
      d="M2 21.7998C2 25.0039 2.95011 28.136 4.73019 30.8001C6.51027 33.4642 9.04036 35.5406 12.0005 36.7667C14.9607 37.9928 18.218 38.3136 21.3605 37.6886C24.5029 37.0635 27.3895 35.5206 29.6551 33.255C31.9207 30.9894 33.4636 28.1028 34.0887 24.9603C34.7138 21.8178 34.393 18.5605 33.1668 15.6004C31.9407 12.6402 29.8643 10.1101 27.2002 8.33005C24.5362 6.54997 21.4041 5.59985 18.2 5.59985M12.8 14.5999V27.1998"
      stroke="currentColor"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.6002 14.6H20.0002C19.5228 14.6 19.065 14.7896 18.7274 15.1272C18.3898 15.4648 18.2002 15.9226 18.2002 16.4V19.1C18.2002 19.5774 18.3898 20.0352 18.7274 20.3728C19.065 20.7104 19.5228 20.9 20.0002 20.9H21.8002C22.2776 20.9 22.7354 21.0896 23.073 21.4272C23.4105 21.7648 23.6002 22.2226 23.6002 22.7V25.4C23.6002 25.8774 23.4105 26.3352 23.073 26.6728C22.7354 27.0104 22.2776 27.2 21.8002 27.2H18.2002M18.2002 5.6H4.7002M4.7002 5.6L8.30019 2M4.7002 5.6L8.30019 9.2"
      stroke="currentColor"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RewindSvg
