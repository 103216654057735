import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useCurrentPage = () => {
  const { pathname, state } = useLocation()
  const paths = useMemo(() => pathname.split('/'), [pathname])
  const previousPaths = useMemo(() => state?.from?.split('/') || [], [state])
  const currentPage = paths[1] ?? ''
  const previousPage = previousPaths[1] ?? ''
  return [currentPage, previousPage]
}

export default useCurrentPage
