import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase } from '@store/types'

import Container from '@components/container'

import { isOffline } from '@utilities/offline-handler-util'

import Disconnect from './disconnect'
import Refresh from './refresh'

interface PagePropsInterface {
  projectIdentity: ProjectIdentity
}

const Tools = ({ projectIdentity }: PagePropsInterface) => (
  <Container>
    <div className="h-full w-full px-4">
      <div className="flex h-top-bar w-full items-center justify-center text-title font-medium text-neutralColour">
        Tools
      </div>
      <div className="no-scrollbar h-page-body w-full overflow-auto">
        <div className="grid grid-rows-2 gap-4">
          {projectIdentity.showClearCacheOption && !isOffline() && (
            <div>
              <Refresh />
            </div>
          )}
          {projectIdentity.disconnect && (
            <div>
              <Disconnect projectIdentity={projectIdentity} />
            </div>
          )}
        </div>
      </div>
    </div>
  </Container>
)

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(Tools)
