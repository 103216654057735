import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import GallerySkeleton from '@components/gallery-skeleton'

import {
  selectFromResult as selectFromBrochureResult,
  useGetBrochureQuery,
} from '@api/brochure'

import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectName: string
}
const Brochure = ({ session, projectName }: PagePropsInterface) => {
  const brochurePayload = useGetBrochureQuery(
    { projectName },
    { selectFromResult: selectFromBrochureResult }
  )

  return (
    <Container>
      <DataHandler
        payload={{ ...brochurePayload, data: brochurePayload.brochureData }}
        skeletonFrame={<GallerySkeleton hasTabBar />}
      >
        <div className="h-full w-full">
          <div className="flex h-top-bar w-full items-center justify-center px-5 text-neutralColour">
            <div className="text-title font-medium">Brochure</div>
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <GalleryHandler
              galleryName="brochure"
              galleries={brochurePayload.brochureData}
              gallerySession={session?.brochure}
              slideView
              previousRoute={String(session?.previousRoute)}
            />
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity: { projectId }, firestore }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName: projectId,
  })
)(Brochure)
