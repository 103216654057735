import React from 'react'

import LevelCard from '@components/level-card'

import { Level, Unit } from '@api/building'

import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

interface ComponentProps {
  isLoaded: boolean
  levels: Array<string>
  handleClick: (arg: string) => void
  buildingLevels: Array<Level>
  envisionActiveLevels: Array<string>
  onlyShowAvailable: boolean
  singleBlockProject: boolean
  activeBuilding: string
  showPrice: boolean
  unitFilter: any
}
const LevelList = ({
  isLoaded,
  levels,
  handleClick,
  buildingLevels,
  envisionActiveLevels,
  onlyShowAvailable,
  activeBuilding,
  singleBlockProject,
  showPrice,
  unitFilter,
}: ComponentProps) => {
  const AVAILABLE_STATUS = 'available'

  const availableUnitsCount = (level: string) => {
    const units = buildingLevels.find((item: Level) => item.level === level)
    const filterUnit = units?.data.filter((unit: Unit) =>
      singleBlockProject ? true : activeBuilding === unit.blockId
    )

    const myFilteredData = filterUnit?.filter((unit) =>
      filterUnitUtil(unit, unitFilter, showPrice)
    )

    if (onlyShowAvailable && myFilteredData?.length) {
      return myFilteredData.filter(
        (unit: Unit) => AVAILABLE_STATUS === unit.metas.status
      ).length
    }
    return myFilteredData?.length ?? 0
  }

  if (!isLoaded)
    return (
      <div className="text-4xl text-secondaryColour">Loading level data...</div>
    )

  return (
    <div className="flex flex-col gap-3">
      {levels.length > 0 ? (
        levels.map((level: string) => (
          <LevelCard
            key={level}
            label={`Level ${level}`}
            handleClick={() => handleClick(level)}
            unitCount={availableUnitsCount(level)}
            onlyShowAvailable={onlyShowAvailable}
            isDisabled={
              !singleBlockProject && !envisionActiveLevels?.includes(level)
            }
          />
        ))
      ) : (
        <div className="cursor-hand flex w-full items-center justify-between rounded-lg bg-secondaryColour px-4 py-10 text-4xl text-mainColour">
          No Levels Found
        </div>
      )}
    </div>
  )
}
export default LevelList
