import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import { getSession } from '@utilities/firebase-util'

import useClearCache from '@hooks/useClearCache'

import { ArrowPathSvg } from '@svg/react'

export interface ComponentProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const Refresh = ({ projectIdentity, session }: ComponentProps) => {
  const [isRefreshing, handleRefreshClick] = useClearCache({
    projectIdentity,
    session,
  })

  return (
    <button
      type="button"
      className="cursor-hand inline-flex w-full items-center justify-center rounded-lg bg-gradient px-20 py-12 text-subHeading font-medium text-neutralColour shadow-standard"
      onClick={handleRefreshClick}
    >
      {isRefreshing ? 'Clearing cache' : 'Clear cache'}
      {isRefreshing && <ArrowPathSvg className="ml-1 h-10 w-10 animate-spin" />}
    </button>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  projectIdentity,
  session: getSession(firestore),
}))(Refresh)
