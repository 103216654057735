import { useEffect } from 'react'

const useScrollEffect = (index: number) => {
  useEffect(() => {
    const tabContainer = document.querySelector(
      `.scroll-container-${index}`
    ) as HTMLDivElement
    const activeTab = document.querySelector(
      `.active-tab-${index}`
    ) as HTMLDivElement
    if (tabContainer && activeTab) {
      const scrollLeftPosition = activeTab.offsetWidth + 15
      tabContainer.scrollTo({
        left: scrollLeftPosition * index,
        behavior: 'smooth',
      })
    }
  }, [index])
}

export default useScrollEffect
