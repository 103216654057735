import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import {
  setAssetHost,
  setAssetPort,
  setRoom,
} from '@store/actionSlices/appConfig'
import { RootStateType } from '@store/store'

import { TextInput } from '@components/form-controls'
import { Modal } from '@components/modals'

import { getQueryStringParams } from '@utilities/helper'

export interface AssetSettingProp {
  assetPort: string
  room: string
  assetHost: string
}

const AssetSettings = ({ assetPort, room, assetHost }: AssetSettingProp) => {
  const [showModal, toggleModal] = React.useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const DASHBOARD_ROUTE = 'dashboard'

  const [port, setPort] = React.useState('')
  const [portInputError, setPortInputError] = React.useState('')
  const [roomValue, setRoomValue] = React.useState('')
  const [roomInputError, setRoomInputError] = React.useState('')
  const [hostValue, setHostValue] = React.useState('')
  const [hostInputError, setHostInputError] = React.useState('')

  const closeModal = () => {
    setPortInputError('')
    setPort(assetPort)
    setRoomInputError('')
    setRoomValue(room)
    setHostInputError('')
    setHostValue(assetHost)
    toggleModal(false)
  }

  const isValidPort = (value: number) => {
    const MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT = 65535
    return value > 0 && value <= MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT
  }

  const handleSave = () => {
    setPortInputError('')
    setRoomInputError('')
    setHostInputError('')
    if (hostValue) {
      dispatch(setAssetHost(hostValue || 'localhost'))
    }

    if (port && !isValidPort(Number(port))) {
      setPortInputError('The given port is not valid.')
      return
    }

    dispatch(setAssetPort(port))

    if (room !== roomValue) {
      dispatch(setRoom(roomValue))
      history.push(DASHBOARD_ROUTE)
      window.location.reload()
    }

    toggleModal(false)
  }

  const handleRemovePort = () => dispatch(setAssetPort(''))

  const handleRemoveRoom = () => {
    dispatch(setRoom(''))
    history.push(DASHBOARD_ROUTE)
    window.location.reload()
  }

  const handleRemoveHost = () => {
    dispatch(setAssetHost(''))
  }

  const handleOnClick = () => {
    toggleModal(true)
  }

  React.useEffect(() => {
    setPort(assetPort)
  }, [assetPort])

  React.useEffect(() => {
    setRoomValue(room)
  }, [room])

  React.useEffect(() => {
    setHostValue(assetHost)
  }, [assetHost])

  React.useEffect(() => {
    const { room: roomParam } = getQueryStringParams(location.search)
    if (roomParam !== undefined) {
      dispatch(setRoom(roomParam))
    }
  }, [])

  return (
    <>
      <button
        type="button"
        className="text-default text-grayColour underline"
        onClick={handleOnClick}
      >
        Asset Settings
      </button>

      <Modal
        title=" Asset Settings"
        isVisible={showModal}
        toggleModal={toggleModal}
        handleClose={closeModal}
        handleApply={() => {
          if (hostValue || port || roomValue) {
            handleSave()
          } else {
            toggleModal(false)
          }
        }}
        applyButtonText="Apply"
      >
        <div className="flex flex-col gap-5">
          <p className="text-center text-default leading-relaxed text-fontBlackColour opacity-60">
            An asset pack hosted on your local machine can be used for improved
            performance.
          </p>
          <div className="flex flex-col gap-2.5">
            <TextInput
              name="assetHost"
              label="Host/IP"
              value={hostValue}
              placeholder="Enter Host/IP"
              className="rounded-lg border-[#D9D9D9]"
              disabled={assetHost !== ''}
              onChange={(event) => setHostValue(event.target.value)}
              onClear={() => {
                if (assetHost) {
                  handleRemoveHost()
                } else {
                  setHostValue('')
                }
              }}
              error={hostInputError}
            />
            <TextInput
              name="port"
              label="Port"
              value={port}
              placeholder="8080"
              disabled={assetPort !== ''}
              onChange={(event) => {
                const numericValue = event.target.value.replace(/\D/g, '')
                setPort(numericValue)
              }}
              onClear={() => {
                if (assetPort) {
                  handleRemovePort()
                } else {
                  setPort('')
                  setPortInputError('')
                }
              }}
              error={portInputError}
            />
            <TextInput
              name="room"
              label="Room"
              value={roomValue}
              placeholder="Enter room"
              disabled={room !== ''}
              onChange={(event) => setRoomValue(event.target.value)}
              onClear={() => {
                if (room) {
                  handleRemoveRoom()
                } else {
                  setRoomValue('')
                }
              }}
              error={roomInputError}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default connect(
  ({ appConfig: { assetPort, room, assetHost } }: RootStateType) => ({
    assetPort,
    room,
    assetHost,
  })
)(AssetSettings)
