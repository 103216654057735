import { Dot } from 'pure-react-carousel'
import React from 'react'

import { HorizontalScrollContainerRef, TabInterface } from '@store/types'

import HorizontalScrollContainer from '@components/horizontal-scroll-container'

import { EyeSvg } from '@svg/react'

interface InteractiveMapTabInterface extends TabInterface {
  activeItem: number
}
export interface ComponentPropsInterface {
  tabs: Array<InteractiveMapTabInterface>
  activeTabIndex: number
  onTabChange: (index: number, label: string) => void
}

const InteractiveMapTab = ({
  tabs,
  activeTabIndex,
  onTabChange,
}: ComponentPropsInterface) => {
  const horizontalScrollWrapperRef =
    React.useRef<HorizontalScrollContainerRef>()

  const handleClick = (tab: InteractiveMapTabInterface) => {
    if (
      horizontalScrollWrapperRef.current &&
      horizontalScrollWrapperRef.current.isDragging()
    ) {
      return
    }

    const { index, label } = tab
    onTabChange(index, label)
  }

  return (
    <HorizontalScrollContainer
      className={`mb-[15px] flex-shrink-0 scroll-container-${activeTabIndex}`}
      ref={horizontalScrollWrapperRef}
    >
      {tabs.map((tab: InteractiveMapTabInterface, i) => (
        <div className="flex last:mr-5" key={tab.index}>
          <span className={`${i === 0 ? 'w-5' : 'w-[15px]'}`}></span>
          <Dot
            key={`interactive-map-tab-${tab.index}`}
            className={`${
              activeTabIndex === tab.index
                ? `active-tab-${activeTabIndex} cursor-default bg-gradient text-neutralColour`
                : 'cursor-pointer bg-neutralColour text-tertiaryColour'
            } flex w-[503px] shrink-0 items-center justify-center gap-2.5 whitespace-nowrap rounded-lg py-[18px] text-center text-subHeading font-medium shadow-standard`}
            onClick={() => handleClick(tab)}
            slide={activeTabIndex}
            disabled={false}
          >
            <>
              <span>{tab.label}</span>
              {tab.activeItem ? (
                <span
                  className={`flex items-center gap-2.5 rounded-xl px-2 ${
                    activeTabIndex === tab.index
                      ? 'bg-neutralColour text-mainColour'
                      : 'bg-tertiaryColour text-white'
                  } `}
                >
                  <EyeSvg
                    className={`h-4 w-4  ${
                      activeTabIndex === tab.index
                        ? 'text-mainColour'
                        : 'text-white'
                    }`}
                  />
                  <span className="text-base">{tab.activeItem}</span>
                </span>
              ) : (
                ''
              )}
            </>
          </Dot>
        </div>
      ))}
    </HorizontalScrollContainer>
  )
}

export default InteractiveMapTab
