import React, { FormEvent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setRoom } from '@store/actionSlices/appConfig'
import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { setToken } from '@store/actionSlices/token'
import { setUser } from '@store/actionSlices/user'
import { RootStateType } from '@store/store'
import { ProjectIdentity, SessionListContent } from '@store/types'

import {
  ProjectPayload,
  TokenUserPayloadInterface,
  useAuthUserMutation,
  useAuthViaTokenMutation,
} from '@api/authentication'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import InputHandler from '@utilities/form-util'
import { getQueryStringParams } from '@utilities/helper'

import { ArrowSvg, DevelopmentIDSvg } from '@svg/react'

import { getClientInformation } from '@adUtilities/client-information'

export interface LoginProps {
  projectIdentity: ProjectIdentity
}

const Login = ({ projectIdentity }: LoginProps) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [formValid, setFormValid] = React.useState(false)

  const [authFail, setAuthFail] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const [projectId, setProjectId] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [password, setPassword] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [email, setEmail] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [authenticateUser, { data: userData, error }] = useAuthUserMutation()
  const [authenticateViaToken, { data: tokenData, error: tokenError }] =
    useAuthViaTokenMutation()

  React.useEffect(() => {
    if (
      !password.isValid ||
      !email.isValid ||
      !password.value ||
      !email.value ||
      isLoading
    ) {
      setFormValid(false)
      return
    }
    setFormValid(true)
  }, [password, email, isLoading])

  const handleSessionArray = async (sessionListJson: string) => {
    try {
      const sessionListArray = JSON.parse(sessionListJson)
      if (sessionListArray instanceof Array) {
        return sessionListArray
          .map((sesh: any) => ({
            projectId: sesh?.project || '',
            sessionId: sesh?.key || '',
            sessionName: sesh?.name || '',
            projectName: '',
            projectUUID: '',
          }))
          .filter(async (res) => {
            const exist = await firebaseControlQuery.doesSessionExist(
              res.sessionId,
              res.projectId
            )
            return exist
          })
      }
    } catch (e) {
      console.error('Invalid Session List.')
    }
    return []
  }

  const handleConnectionUpdate = (sessionList: SessionListContent[]) => {
    sessionList.forEach((res) => {
      firebaseControlQuery.updateConnection(true, res.sessionId, res.projectId)
    })
  }

  const authProcedure = async (
    {
      refresh_token,
      access_token,
      email: userEmail,
      _id: userId,
    }: TokenUserPayloadInterface,
    { id, canonical_name, name }: ProjectPayload
  ) => {
    const { sessionKey, disconnect, clearCache, room, sessionList, masterKey } =
      getQueryStringParams(location.search)

    const updatedSessionList = await handleSessionArray(sessionList)

    handleConnectionUpdate(updatedSessionList)

    setIsLoading(false)

    if (room) {
      dispatch(setRoom(room))
    }

    dispatch(
      setToken({ refresh_token, access_token, email: userEmail, _id: userId })
    )

    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        projectUUID: id,
        projectName: name,
        projectId: canonical_name,
        sessionId: sessionKey || '',
        disconnect: disconnect ? disconnect === 'true' : true,
        showClearCacheOption: clearCache ? clearCache === 'true' : true,
        sessionList: updatedSessionList,
        masterKey: masterKey ?? '',
      })
    )
  }

  const autoAuth = async () => {
    const { authenticate } = getQueryStringParams(location.search)
    if (authenticate && projectId.value) {
      setIsLoading(true)
      const exists = await firebaseControlQuery.isProjectEnabled(
        projectId.value
      )
      if (exists) {
        const clientInformation = await getClientInformation()
        authenticateViaToken({
          token: authenticate,
          projectId: projectId.value,
          clientInformation: { ...clientInformation, client: 'remote' },
        })
        return
      }
      setIsLoading(false)
      setAuthFail(true)
    }
  }

  const regularAuth = async () => {
    setIsLoading(true)
    if (projectId.value) {
      const exists = await firebaseControlQuery.isProjectEnabled(
        projectId.value
      )
      if (exists) {
        const clientInformation = await getClientInformation()
        authenticateUser({
          project_canonical_name: projectId.value,
          password: password.value,
          email: email.value,
          clientInformation: { ...clientInformation, client: 'remote' },
        })
        return
      }
    }
    setIsLoading(false)
    setAuthFail(true)
  }

  const submitAuthForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    regularAuth()
  }

  React.useEffect(() => {
    if (tokenData && tokenData.data) {
      const {
        data: { user, project },
      } = tokenData
      if (user) {
        authProcedure(user, project)
        dispatch(
          setUser({
            name: user.name,
            email: user.email,
          })
        )
      }
    }
  }, [tokenData])

  React.useEffect(() => {
    if (userData && userData.data) {
      const {
        data: { user, project },
      } = userData
      if (user) {
        authProcedure(user, project)
        dispatch(
          setUser({
            name: user.name,
            email: user.email,
          })
        )
      }
    }
  }, [userData])

  React.useEffect(() => {
    if (error) {
      setAuthFail(true)
      setIsLoading(false)
    }
  }, [error])

  React.useEffect(() => {
    if (tokenError) {
      setAuthFail(true)
      setIsLoading(false)
    }
  }, [tokenError])

  React.useEffect(() => {
    const { authenticate } = getQueryStringParams(location.search)
    if (authenticate && projectId.value) {
      autoAuth()
    }
  }, [projectId])

  React.useEffect(() => {
    const paths = location.pathname.split('/')
    if (paths.length > 1) {
      setFormValid(true)
      setProjectId({
        ...projectId,
        value: paths[paths.length - 1],
      })
    }
  }, [])

  return (
    <div className="h-screen w-screen bg-[#171A21]">
      <div className="p-6">
        <DevelopmentIDSvg />
      </div>
      <div className="no-scrollbar h-login-page-container overflow-auto">
        <div className="sm-h:p-4 mx-auto w-1/2 p-4 ipad-mini:w-[36rem] ipad-mini:p-0">
          <div>
            <p className="text-left text-heading font-medium text-white">
              Login
            </p>
            <form
              className="mt-[40px] flex flex-col gap-5 text-default font-medium text-white"
              onSubmit={submitAuthForm}
            >
              <>
                <div>
                  <div className="mb-3 flex text-subHeading ">
                    <label htmlFor="display-id">Display ID</label>
                    <span className="text-[#017EE4]">*</span>
                  </div>
                  <input
                    onChange={(e) => InputHandler(e, setProjectId)}
                    className={`login-input ${
                      projectId.isValid
                        ? 'border-transparent'
                        : 'border-2 border-red-500'
                    }`}
                    id="display-id"
                    type="text"
                    placeholder="id-development"
                    value={projectId.value}
                    required
                    disabled={isLoading}
                  />
                  {projectId.message && (
                    <p className="mt-3 text-default text-red-500">
                      {projectId.message}
                    </p>
                  )}
                </div>
                <div>
                  <div className="mb-3 flex text-subHeading">
                    <label htmlFor="username">Email</label>
                    <span className="text-[#017EE4]">*</span>
                  </div>
                  <input
                    onChange={(e) => InputHandler(e, setEmail)}
                    className={`login-input ${
                      email.isValid
                        ? 'border-transparent'
                        : 'border-2 border-red-500'
                    }`}
                    id="username"
                    type="email"
                    placeholder="example@gmail.com"
                    value={email.value}
                    required
                    disabled={isLoading}
                  />
                  {email.message && (
                    <p className="mt-3 text-red-500">Invalid Email Format.</p>
                  )}
                </div>
                <div>
                  <div className="mb-3 flex text-subHeading">
                    <label htmlFor="password">Password</label>
                    <span className="text-[#017EE4]">*</span>
                  </div>
                  <input
                    onChange={(e) => InputHandler(e, setPassword)}
                    className={`login-input ${
                      password.isValid
                        ? 'border-transparent'
                        : 'border-2 border-red-500'
                    }`}
                    id="password"
                    type="password"
                    name="password"
                    placeholder="********"
                    autoComplete="on"
                    value={password.value}
                    required
                    disabled={isLoading}
                  />
                  {password.message && (
                    <p className="mt-3 text-red-500">{password.message}</p>
                  )}
                  {authFail && (
                    <p className="mt-3 text-red-500">
                      You entered invalid user credentials.
                    </p>
                  )}
                </div>
              </>
              <div className="flex items-center justify-center">
                <button
                  className={`focus:shadow-outline sm-h:py-4 relative flex w-full items-center justify-center rounded-lg py-7 focus:outline-none ipad-pro-land:p-5 ${
                    formValid ? 'bg-[#017EE4]' : 'bg-grayColour'
                  }`}
                  type="submit"
                  disabled={!formValid || isLoading}
                >
                  Login
                  <div
                    className={`absolute ${isLoading ? 'right-8' : 'right-4'}`}
                  >
                    {isLoading ? (
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="border-white-400 h-7 w-7 animate-spin rounded-full border-2"
                      ></div>
                    ) : (
                      <ArrowSvg rotate="right" strokeColor="#FFF7E9" />
                    )}
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateType) => ({
  projectIdentity,
}))(Login)
