import React from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  setFirebaseHost as storeFirebaseHost,
  setFirebasePort as storeFirebasePort,
  setOfflineMode as storeOfflineMode,
} from '@store/actionSlices/appConfig'
import { RootStateType } from '@store/store'

import { TextInput } from '@components/form-controls'
import { Modal } from '@components/modals'
import ToggleSwitch from '@components/toggle-switch'

import { removeOfflineResponseFromLocalStorage } from '@utilities/offline-handler-util'

export interface OfflineModeProp {
  currentOfflineMode: boolean
  currentFirebaseHost: string
  currentFirebasePort: string
}

const OfflineMode = ({
  currentOfflineMode,
  currentFirebaseHost,
  currentFirebasePort,
}: OfflineModeProp) => {
  const dispatch = useDispatch()

  const [showModal, toggleModal] = React.useState(false)
  const [offlineToggleSwitchState, setOfflineToggleSwitchState] =
    React.useState(false)

  const [
    firebaseOfflineToggleSwitchState,
    setFirebaseOfflineToggleSwitchState,
  ] = React.useState(false)
  const [firebasePort, setFirebasePort] = React.useState('')
  const [firebaseHost, setFirebaseHost] = React.useState('localhost')
  const [firebasePortError, setFirebasePortError] = React.useState('')
  const [firebaseHostError, setFirebaseHostError] = React.useState('')
  const [message, setMessage] = React.useState('')

  const isValidPort = (value: number) => {
    const MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT = 65535
    return value > 0 && value <= MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT
  }

  const handleOfflineToggleSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked: state },
    } = event
    setOfflineToggleSwitchState(state)
    setMessage('')
    if (state) {
      setMessage('This will cache the application data.')
    }
  }

  const handleFirebaseOfflineToggleSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked: state },
    } = event
    setFirebaseOfflineToggleSwitchState(state)
  }

  const handleSave = () => {
    setFirebasePortError('')
    setFirebaseHostError('')
    if (firebaseOfflineToggleSwitchState && !firebaseHost) {
      setFirebaseHostError('Host/IP field is required.')
      return
    }

    if (firebaseOfflineToggleSwitchState && !firebasePort) {
      setFirebasePortError('Port field is required.')
      return
    }

    if (
      firebaseOfflineToggleSwitchState &&
      !isValidPort(Number(firebasePort))
    ) {
      setFirebasePortError('The given port is not valid.')
      return
    }

    dispatch(storeOfflineMode(offlineToggleSwitchState))
    dispatch(
      storeFirebaseHost(firebaseOfflineToggleSwitchState ? firebaseHost : '')
    )
    dispatch(
      storeFirebasePort(firebaseOfflineToggleSwitchState ? firebasePort : '')
    )

    if (!offlineToggleSwitchState) {
      removeOfflineResponseFromLocalStorage()
    }

    window.location.reload()
  }

  const handleClear = () => {
    setFirebaseHost('localhost')
    setFirebasePort('')
    setFirebasePortError('')
    setFirebaseHostError('')
  }

  const handleClose = () => {
    setOfflineToggleSwitchState(currentOfflineMode)
    setFirebaseOfflineToggleSwitchState(currentFirebasePort !== '')
    setFirebaseHost(currentFirebaseHost)
    setFirebasePort(currentFirebasePort)
    setFirebasePortError('')
    setMessage('')
    toggleModal(false)
  }

  React.useEffect(() => {
    setOfflineToggleSwitchState(currentOfflineMode)
  }, [currentOfflineMode])

  React.useEffect(() => {
    setFirebaseOfflineToggleSwitchState(currentFirebasePort !== '')
    setFirebaseHost(currentFirebaseHost || 'localhost')
    setFirebasePort(currentFirebasePort)
  }, [currentFirebasePort, currentFirebaseHost])

  return (
    <>
      <button
        type="button"
        className="text-default text-grayColour underline"
        onClick={() => {
          toggleModal(true)
        }}
      >
        Offline Settings
      </button>

      <Modal
        title="Offline Settings"
        isVisible={showModal}
        toggleModal={toggleModal}
        handleClose={handleClose}
        handleApply={() => {
          handleSave()
        }}
        applyButtonText="Apply"
      >
        <div className="flex flex-col gap-5">
          <p className="text-center text-default leading-relaxed text-fontBlackColour opacity-60">
            Use showcase on your local network
          </p>
          <div className="flex flex-col gap-2.5">
            <div className="flex items-center justify-between rounded-lg border p-2 text-default font-medium text-fontBlackColour">
              <span className="px-2">Offline Data</span>
              <ToggleSwitch
                key="offlineToggleSwitch"
                id="offlineToggleSwitch"
                onChangeCallback={handleOfflineToggleSwitch}
                initialState={offlineToggleSwitchState}
                onColor="bg-[#4DD865]"
              />
            </div>
            <p className="text-center text-base leading-relaxed text-fontBlackColour opacity-60 ">
              Cache this remote&apos;s data to use offline (excluding media).
            </p>
          </div>

          <div className="flex flex-col gap-2.5">
            <div className="rounded-lg border p-2">
              <div className="flex flex-col">
                <div
                  className={`flex items-center justify-between border-[#D9D9D9] text-default font-medium  text-fontBlackColour ${
                    firebaseOfflineToggleSwitchState ? 'border-b py-2' : ''
                  }`}
                >
                  <span>Offline Connect</span>
                  <ToggleSwitch
                    key="firebaseOfflineToggleSwitch"
                    id="firebaseOfflineToggleSwitch"
                    onChangeCallback={handleFirebaseOfflineToggleSwitch}
                    initialState={firebaseOfflineToggleSwitchState}
                    onColor="bg-[#4DD865]"
                  />
                </div>

                <TextInput
                  className={`rounded-none !px-0 ${
                    firebaseOfflineToggleSwitchState
                      ? 'border-[#D9D9D9]'
                      : 'hidden'
                  }`}
                  border="border-b"
                  label="Host/IP"
                  placeholder="localhost"
                  value={firebaseHost}
                  name="firebaseHost"
                  onChange={(event) => setFirebaseHost(event.target.value)}
                  onClear={() => {
                    setFirebaseHost('')
                    setFirebaseHostError('')
                  }}
                  error={firebaseHostError}
                />

                <TextInput
                  className={`!px-0 ${
                    firebaseOfflineToggleSwitchState ? 'border-none' : 'hidden'
                  }`}
                  name="port"
                  label="Port"
                  value={firebasePort}
                  placeholder="8080"
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, '')
                    setFirebasePort(numericValue)
                  }}
                  onClear={() => {
                    setFirebasePort('')
                    setFirebasePortError('')
                  }}
                  error={firebasePortError}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2.5">
              {firebaseOfflineToggleSwitchState && (
                <div className="mr-2 flex justify-end">
                  <button
                    type="button"
                    className="text-xs text-blue-500"
                    onClick={() => handleClear()}
                  >
                    Clear
                  </button>
                </div>
              )}
              <p className="text-center text-base leading-relaxed text-gray-500  ">
                Connect this remote to Showcase your local network
              </p>

              <p className="text-center text-base leading-relaxed text-red-500  ">
                {message}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default connect(
  ({
    appConfig: {
      offlineMode: currentOfflineMode,
      firebaseHost: currentFirebaseHost,
      firebasePort: currentFirebasePort,
    },
  }: RootStateType) => ({
    currentOfflineMode,
    currentFirebaseHost,
    currentFirebasePort,
  })
)(OfflineMode)
