import { LotFilterInterface } from '@store/types'

const FILTER_INITIAL_STATE_HOUSE_AND_LAND: LotFilterInterface = {
  configurations: {
    bed: { min: 'Any', max: 'Any' },
    study: { min: 'Any', max: 'Any' },
    bath: { min: 'Any', max: 'Any' },
    powderRoom: { min: 'Any', max: 'Any' },
    car: { min: 'Any', max: 'Any' },
  },
  builder: [],
  storey: [],
  lotConfiguration: {
    frontage: { min: 'Any', max: 'Any' },
    area: { min: 'Any', max: 'Any' },
    price: { min: 'Any', max: 'Any' },
    aspects: [],
  },
  showOnlyAvailable: false,
  anyStage: true,
  apply: false,
}

export default FILTER_INITIAL_STATE_HOUSE_AND_LAND
