import React from 'react'

import PrecinctCard from '@components/precinct-card'

import { PrecinctListItemInterface } from '@api/houseAndLand'

interface ComponentPropsInterface {
  precinctList: PrecinctListItemInterface[]
  handleClick: (arg: string) => void
}

const PrecinctList = ({
  precinctList,
  handleClick,
}: ComponentPropsInterface) => (
  <>
    {precinctList.length > 0 ? (
      precinctList.map((res) => (
        <PrecinctCard
          key={res.id}
          label={res.label}
          handleClick={() => handleClick(res.label)}
        />
      ))
    ) : (
      <div className="cursor-hand w-full rounded-lg bg-gradient py-10 pl-4 text-heading font-medium text-neutralColour shadow-standard">
        No data
      </div>
    )}
  </>
)

export default PrecinctList
