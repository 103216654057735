import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setProjectIdentity } from '@src/store/actionSlices/projectIdentity'

import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'

import {
  selectFromResult,
  useGetProjectListQuery,
  useLazyGetProjectListQuery,
} from '@api/project-list'

import { getSession } from '@utilities/firebase-util'

import constants from './constants'
import CreateSessionHandler from './create-session-handler'
import ProjectSwitchHandler from './project-switch-handler'
import RefreshSessionHandler from './refresh-session-handler'
import SessionCard from './session-card'

export interface SessionSettingsProps {
  projectIdentity: ProjectIdentity
  email: string
  session: SessionMap | undefined
}

const SessionSettings = ({
  projectIdentity,
  email,
  session,
}: SessionSettingsProps) => {
  const dispatch = useDispatch()

  const [page, setPage] = React.useState(1)
  const [query, setQuery] = React.useState('')

  const [trigger] = useLazyGetProjectListQuery()

  const { list, pagination, isLoaded } = useGetProjectListQuery(
    {
      email,
      page,
      query,
    },
    { selectFromResult }
  )

  const getPageCount = React.useMemo(
    () => Math.ceil((pagination?.total || 0) / constants.PAGE_SIZE),
    [pagination]
  )

  const getProjectPage = (status: 'prev' | 'next') => {
    const maxPages = getPageCount
    setPage((state: number) => {
      if (status === 'prev') {
        return state > 1 ? state - 1 : 1
      }
      if (status === 'next' && state < maxPages) {
        return state + 1
      }
      return state
    })
  }

  const getSessionListProjectInfo = React.useCallback(async () => {
    const { sessionList } = projectIdentity

    const promises = Promise.all(
      sessionList.map(async (sess) => {
        if (!sess.projectUUID || !sess.projectName) {
          const response = await trigger({
            email,
            page: 1,
            query: sess.projectId.replace(/[^a-zA-Z ]/g, ' '),
          }).unwrap()

          const project = response?.data?.lists?.[0]

          if (project) {
            return {
              ...sess,
              projectUUID: project.id,
              projectName: project.title,
            }
          }
          return sess
        }
        return sess
      })
    )

    const validatedSessionList = await promises

    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        sessionList: validatedSessionList,
      })
    )
  }, [])

  React.useEffect(() => {
    const { sessionList, masterKey } = projectIdentity

    if (masterKey) {
      return
    }

    if (sessionList.find((res) => !res.projectUUID || !res.projectName)) {
      getSessionListProjectInfo()
    }
  }, [projectIdentity])

  return (
    <Container className="overflow-hidden">
      <div className="p-5">
        <div className="mb-8">
          <RefreshSessionHandler
            projectIdentity={projectIdentity}
            session={session}
          />
        </div>
        <div className="flex items-center">
          <span className="text-title font-medium leading-none text-neutralColour">
            Session settings
          </span>
          <ProjectSwitchHandler
            projectIdentity={projectIdentity}
            setPage={getProjectPage}
            projectList={list}
            pagination={pagination}
            pageCount={getPageCount}
            currentPage={page}
            isActive={isLoaded}
            setProjectQuery={(qry) => setQuery(qry)}
          />
        </div>
        <div className="mt-4">
          <CreateSessionHandler
            projectIdentity={projectIdentity}
            setPage={getProjectPage}
            projectList={list}
            pageCount={getPageCount}
            pagination={pagination}
            isActive={isLoaded}
            setProjectQuery={(qry) => setQuery(qry)}
          />
        </div>
      </div>
      <div className="auto-row-fr grid h-4/5 grid-flow-row grid-cols-2 gap-5 overflow-auto px-5 pb-10">
        {projectIdentity.sessionList
          .filter((res) => res.projectId === projectIdentity.projectId)
          .map((sess, index) => (
            <SessionCard
              key={`${sess.sessionId}-${index.toString()}`}
              isActive={sess.sessionId === projectIdentity.sessionId}
              projectIdentity={projectIdentity}
              {...sess}
            />
          ))}
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, user: { email }, firestore }: RootStateFirebase) => ({
    projectIdentity,
    email,
    session: getSession(firestore),
  })
)(SessionSettings)
