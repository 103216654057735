import React from 'react'

export interface SessionFieldProps {
  convertToSessionId: (id: string) => void
  sessionId: string
  className?: string
}

const SessionField = ({
  convertToSessionId,
  sessionId,
  className,
}: SessionFieldProps) => {
  const [values, setValues] = React.useState<Array<string>>(
    new Array(6).fill('')
  )

  const inputRefs = React.useRef<Array<HTMLInputElement>>([])

  const isValidSessionId = (value: string) =>
    !Number.isNaN(value) && /^\d{6}$/.test(value)

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const invalidChars = ['e', 'E', '+', '-']
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
    const newValues = [...values]
    const hasValue = newValues[index]
    switch (e.key) {
      case 'Backspace':
      case 'Delete':
        if (!hasValue && newValues[index - 1]) {
          newValues[index - 1] = ''
        } else {
          newValues[index] = ''
        }
        setValues(newValues)
        convertToSessionId(newValues.join(''))
        if (index > 0 && !hasValue) {
          inputRefs.current[index - 1].focus()
        }
        break
      case 'ArrowLeft':
        if (index > 0) {
          inputRefs.current[index - 1].focus()
        }
        break
      case 'ArrowRight':
        if (index < 5) {
          inputRefs.current[index + 1].focus()
        }
        break
      default:
        if (/^\d$/.test(e.key) && index <= 5) {
          if (hasValue && !newValues[index + 1] && index < 5) {
            newValues[index + 1] = e.key
          } else if (!hasValue) {
            newValues[index] = e.key
          }
          setValues(newValues)
          convertToSessionId(newValues.join(''))
          if (index < 5) {
            inputRefs.current[index + 1].focus()
          }
        }
    }
  }

  const generateInputRef = (el: HTMLInputElement) => inputRefs.current.push(el)

  React.useEffect(() => {
    if (isValidSessionId(sessionId)) {
      setValues(sessionId.split(''))
      return
    }
    if (sessionId.length === 0) {
      setValues(Array(6).fill(''))
    }
  }, [sessionId])

  return (
    <div className="flex gap-4">
      {values.map((val, index) => (
        <input
          key={`field-${index}` as string}
          className={`${className} session-id-input focus:border-4 focus:border-mainColour`}
          id={`sessionId-${index}`}
          type="number"
          value={val}
          required
          autoComplete="off"
          onChange={() => {}}
          onKeyDown={(e) => {
            handleKeyDown(e, index)
          }}
          onPaste={(e) => {
            e.preventDefault()
            const text = e.clipboardData.getData('text/plain')?.trim()
            if (isValidSessionId(text)) {
              setValues(text.split(''))
              convertToSessionId(text)
              return
            }
            if (/^\d+$/.test(text)) {
              const newValues = [...values]
              for (let i = index, j = 0; i < values.length; i += 1, j += 1) {
                newValues[i] = text[j] || ''
              }
              setValues(newValues)
              convertToSessionId(newValues.join(''))
            }
          }}
          ref={generateInputRef}
        />
      ))}
    </div>
  )
}

export default SessionField
