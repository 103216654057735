import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { LotGallery } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface UnitGalleryInterface {
  id: string
  src: string
  label: string
  type: string
  thumbnail?: string
}

type UnitGalleryTypeInterface = {
  projectName: string
  unitId: string
}

export const unitGalleryApi = createApi({
  reducerPath: 'unitGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getUnitGallery: builder.query<
      ApiResponse<Array<UnitGalleryInterface>>,
      UnitGalleryTypeInterface
    >({
      query: (params: UnitGalleryTypeInterface) => ({
        url: `/v1/${params.projectName}/unit-galleries/${
          params.unitId
        }${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})
export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
  isFetching,
}: UseQueryStateResult<any, any>): {
  unitGalleryData: Array<LotGallery>
  isLoaded: boolean
  isError: boolean
  isFetching: boolean
  status: string
  errorStatus: number
} => ({
  unitGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  isFetching,
  errorStatus: error?.status,
})
export const { useGetUnitGalleryQuery } = unitGalleryApi
