import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId, getSession } from '@utilities/firebase-util'

import { notifyError } from '@adUtilities/notifier'

export interface ComponentProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const IdleTimeHandler = ({ session, projectIdentity }: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const events = ['mousemove', 'click', 'keyup', 'touchstart', 'touchmove']

  const [, setLastAppUsageTime] = React.useState(Math.floor(Date.now() / 1000))
  const [appIdleKeyResetterId, setAppIdleKeyResetterId] =
    React.useState<ReturnType<typeof setTimeout>>()
  const [appIdleKeyFlag, updateAppIdleKeyFlag] = React.useState(false)
  const [appIdleKey, setAppIdleKey] = React.useState('')
  const [activeSessionId, setActiveSessionId] = React.useState('')
  const [eventRegistered, setEventRegistrationStatus] = React.useState(false)
  const [
    triggerProcessingWindowOnSessionSwitch,
    setTriggerProcessingWindowOnSessionSwitch,
  ] = React.useState(false)

  const updateAppIdleKey = (key = '') => {
    firebaseControlQuery.updateCollection(`appIdleKey`, key)
  }

  const checkTimeElapsed = React.useCallback(() => {
    const currentTime = Math.floor(Date.now() / 1000)
    setLastAppUsageTime((prevTime) => {
      const differenceInSeconds = currentTime - prevTime
      updateAppIdleKeyFlag(differenceInSeconds > 120)
      return currentTime
    })
  }, [])

  const handleActiveSessionProcessingWindow = () => {
    if (!activeSessionId) {
      setTriggerProcessingWindowOnSessionSwitch(false)
      return
    }

    if (!triggerProcessingWindowOnSessionSwitch) {
      setTriggerProcessingWindowOnSessionSwitch(true)
      return
    }

    updateAppIdleKey(generateId(10))
    setLastAppUsageTime(Math.floor(Date.now() / 1000))
  }

  const registerEvent = () => {
    events.forEach((event: string) =>
      window.addEventListener(event, checkTimeElapsed, true)
    )
    setEventRegistrationStatus(true)
  }

  const removeEvent = () => {
    events.forEach((event: string) =>
      window.removeEventListener(event, checkTimeElapsed, true)
    )
    setEventRegistrationStatus(false)
  }

  const handleEventRegistration = () => {
    const { sessionId, projectId } = projectIdentity

    setActiveSessionId(sessionId || '')

    if (!sessionId || !projectId) {
      removeEvent()
      return
    }

    if (!eventRegistered) {
      registerEvent()
    }
  }

  const resetAppIdleKeyTimer = () => {
    if (appIdleKeyResetterId) {
      clearTimeout(appIdleKeyResetterId)
      setAppIdleKeyResetterId(undefined)
    }
  }

  const setAppIdleKeyResetter = () => {
    if (!appIdleKey) {
      resetAppIdleKeyTimer()
      return
    }

    if (!appIdleKeyResetterId) {
      setAppIdleKeyResetterId(
        setTimeout(() => {
          updateAppIdleKey()
          notifyError(
            'Bad or Slow network connection. Showcase did not respond on time.'
          )
        }, 20000)
      )
    }
  }

  React.useEffect(() => {
    const { sessionId, projectId } = projectIdentity
    if (appIdleKeyFlag && sessionId && projectId) {
      updateAppIdleKey(generateId(10))
    }
  }, [appIdleKeyFlag])

  React.useEffect(() => {
    if (session) {
      const { appIdleKey: appIdleKeyFirebase } = session
      setAppIdleKey(appIdleKeyFirebase)
    }
  }, [session])

  React.useEffect(() => {
    handleActiveSessionProcessingWindow()
  }, [activeSessionId])

  React.useEffect(() => {
    handleEventRegistration()
  }, [projectIdentity])

  React.useEffect(() => {
    setAppIdleKeyResetter()
  }, [appIdleKey])

  if (appIdleKey === '') return null

  return (
    <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-90">
      <div className="absolute left-41% top-50% text-xl font-medium text-white">
        Re-establishing Connection, just a second…
      </div>
    </div>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(IdleTimeHandler)
