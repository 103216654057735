import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import {
  PrecinctListItemInterface,
  SummaryCollectionInterface,
} from '@api/houseAndLand'

export interface HouseAndLandInterface {
  packageSummary: SummaryCollectionInterface | null
  precinctList: Array<PrecinctListItemInterface>
}

const data = {
  packageSummary: null,
  precinctList: [],
}

const initialState: HouseAndLandInterface = {
  ...data,
}

const houseAndLandSlice = createSlice({
  name: 'houseAndLand',
  initialState,
  reducers: {
    setPackageSummary: (
      state: HouseAndLandInterface,
      action: PayloadAction<SummaryCollectionInterface>
    ) => ({
      ...state,
      packageSummary: { ...state.packageSummary, ...action.payload },
    }),
    setPrecinctList: (
      state: HouseAndLandInterface,
      action: PayloadAction<Array<PrecinctListItemInterface>>
    ) => {
      const newState = {
        ...state,
        precinctList: action.payload,
      }
      return newState
    },
  },
})

export const { setPackageSummary, setPrecinctList } = houseAndLandSlice.actions

export const selectHouseAndLand = (state: RootStateTypeExtra) =>
  state.houseAndLand

export default houseAndLandSlice.reducer
