import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { TokenInterface } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import AbsoluteContainer from '@components/container/absolute-container'
import ErrorBoundaryFallback from '@components/error-boundary-fallback'
import Navigation from '@components/navigation'
import {
  ProtectedRoutes,
  PublicRoutes,
  SessionActiveRoutes,
} from '@components/route-guards'
import WithPageTransition from '@components/transition'

import PageNotFound from '@pages/404'
import AreaViewHouseAndLand from '@pages/area-view-house-and-land'
import Brochure from '@pages/brochure'
import Building from '@pages/building'
import Dashboard from '@pages/dashboard'
import EnvisionVR from '@pages/envision-vr'
import ExternalViews from '@pages/external-views'
import Gallery from '@pages/gallery'
import { AreaView, LevelView } from '@pages/interactive-building'
import InteractiveMap from '@pages/interactive-map'
import Location from '@pages/location'
import Login from '@pages/login'
import LotView from '@pages/lot-view'
import Panoramic from '@pages/panoramic'
import Precinct from '@pages/precinct'
import Shortlist from '@pages/shortlist'
import Snaploader from '@pages/snaploader'
import Stage from '@pages/stage'
import Team from '@pages/team'
import Tools from '@pages/tools'
import VideoGallery from '@pages/video-gallery'
import Vision from '@pages/vision'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import useCurrentPage from '@utilities/location-util'
import { hasToken } from '@utilities/token-helper'

import SessionSettings from './pages/session-settings'

interface RouteProps {
  activeRouteFirebase: string
  token: TokenInterface
  projectIdentity: ProjectIdentity
}

const Routes = ({
  activeRouteFirebase,
  token,
  projectIdentity,
}: RouteProps) => {
  const history = useHistory()
  const location = useLocation()
  const [currentPage] = useCurrentPage()
  const IGNORE_ROUTER_PUSHER = ['/session-settings', '/tools', '/lot-view']
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const disableNavigation = React.useMemo(
    () =>
      !hasToken(token) ||
      currentPage === '' ||
      currentPage === 'vision' ||
      currentPage === 'dashboard',
    [currentPage, token]
  )

  React.useEffect(() => {
    if (activeRouteFirebase) {
      if (IGNORE_ROUTER_PUSHER.includes(location.pathname)) {
        return
      }
      if (
        location.pathname === '/shortlist' &&
        activeRouteFirebase === '/stages'
      ) {
        return
      }
      if (activeRouteFirebase !== location.pathname) {
        history.push({
          pathname: activeRouteFirebase,
          state: { from: location.pathname },
        })
      }
      if (activeRouteFirebase !== '/interactive-map') {
        firebaseControlQuery.update({
          interactiveMap: [],
        })
      }
    }
  }, [activeRouteFirebase])

  return (
    <>
      {disableNavigation ? null : <Navigation type="left-navigation" />}
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <WithPageTransition>
          <Switch location={location}>
            <SessionActiveRoutes
              exact
              path="/gallery"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Gallery />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/video-gallery"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <VideoGallery />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/external-views"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <ExternalViews />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/brochure"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Brochure />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/location"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Location />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/interactive-map"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <InteractiveMap />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              path="/level-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <LevelView />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              exact
              path="/shortlist"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Shortlist />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/area-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <AreaView />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/area-view-house-and-land"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <AreaViewHouseAndLand />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              path="/precinct"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Precinct />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              path="/stages"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Stage />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              exact
              path="/building"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Building />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/lot-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <LotView />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/vision"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Vision />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/teams"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Team />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/panoramic"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Panoramic />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path="/snaploader-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Snaploader />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path="/3d-building"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <EnvisionVR />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path="/session-settings"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <SessionSettings />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path="/tools"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Tools />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <ProtectedRoutes
              exact
              path="/dashboard"
              authenticationPath="/"
              redirectSessionPath="/vision"
            >
              <AbsoluteContainer>
                <Dashboard />
              </AbsoluteContainer>
            </ProtectedRoutes>
            <PublicRoutes
              exact
              path="/:projectId"
              authenticatedPath="/dashboard"
            >
              <Login />
            </PublicRoutes>
            <PublicRoutes exact path="/" authenticatedPath="/dashboard">
              <Login />
            </PublicRoutes>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </WithPageTransition>
      </ErrorBoundary>
    </>
  )
}

const getActiveRouteFromFirebase = (session: SessionMap | undefined) => {
  if (session) {
    const { activeRoute } = session
    return `/${activeRoute}`
  }
  return ''
}

export default connect(({ firestore, projectIdentity }: RootStateFirebase) => ({
  activeRouteFirebase: getActiveRouteFromFirebase(getSession(firestore)),
  projectIdentity,
}))(Routes)
