import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import GalleryHandler from '@components/gallery-handler'
import PlayerControl from '@components/player-control'

import {
  FloorPlateGalleryInterface,
  FloorPlateGalleryItemInterface,
} from '@api/floor-plate-gallery'

import { getSession } from '@utilities/firebase-util'
import { getFileNameFromPathOrURL } from '@utilities/helper'

export interface FloorGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}

export interface ComponentPropsInterface {
  floorPlateGalleryData: Array<FloorPlateGalleryInterface>
  floorGalleryControlSession: FloorGalleryControlSessionInterface | undefined
  activeLevel?: string
  activeBlock?: string
  activePrecinct?: string
  activeStage?: string
  session: SessionMap | undefined
}

const FloorPlateGallery = ({
  floorPlateGalleryData,
  floorGalleryControlSession,
  activeLevel,
  activeBlock,
  activePrecinct,
  activeStage,
  session,
}: ComponentPropsInterface) => {
  const [floorGalleries, setFloorGalleries] = useState<GalleryInterface[]>([])
  const [currentVideoPlayback, setCurrentVideoPlayback] = useState<string>('')

  const getFloorAssets = (): FloorPlateGalleryItemInterface[] => {
    const matchingFloorPlate: FloorPlateGalleryInterface | undefined =
      floorPlateGalleryData.find((item: FloorPlateGalleryInterface) => {
        const matchesBlock =
          activePrecinct && activePrecinct !== ''
            ? item.precinct.toString() === activePrecinct.toString()
            : true

        const matchesLevel =
          activeLevel && activeLevel !== ''
            ? item.level?.toString() === activeLevel.toString()
            : true

        const matchesPrecinct =
          activePrecinct && activePrecinct !== ''
            ? item.precinct.toString() === activePrecinct.toString()
            : true

        const matchesStage =
          activeStage && activeStage !== ''
            ? item.stage.toString() === activeStage.toString()
            : true

        return matchesBlock && matchesLevel && matchesPrecinct && matchesStage
      })

    return matchingFloorPlate?.assets ?? []
  }

  const buildGallery = (floorAssets: FloorPlateGalleryItemInterface[]) => {
    const galleryItems: GalleryItemInterface[] = floorAssets.map(
      (asset: FloorPlateGalleryItemInterface) => ({
        id: asset.id,
        imageSource: asset.imageSrc ?? '',
        videoSource: asset.videoSrc ?? '',
        title: asset.title ?? getFileNameFromPathOrURL(asset.imageSrc ?? ''),
      })
    )

    if (galleryItems.length === 0) {
      return
    }

    setFloorGalleries([{ items: galleryItems }])
  }

  useEffect(() => {
    const hasActiveFields = Boolean(
      (activeLevel && activeLevel !== '') ||
        (activeBlock && activeBlock !== '') ||
        (activePrecinct && activePrecinct !== '') ||
        (activeStage && activeStage)
    )

    if (!hasActiveFields) {
      return
    }

    const floorAssets = getFloorAssets()
    buildGallery(floorAssets)
  }, [
    floorPlateGalleryData,
    activeLevel,
    activeBlock,
    activePrecinct,
    activeStage,
  ])

  return (
    <>
      {floorGalleries.length > 0 ? (
        <>
          <GalleryHandler
            showPlayButton
            galleryName="floorGallery"
            galleries={floorGalleries}
            gallerySession={floorGalleryControlSession}
            showTab={false}
            onVideoPlayback={setCurrentVideoPlayback}
            previousRoute={String(session?.previousRoute)}
          />
          <PlayerControl
            galleryName="floorGallery"
            playerSession={floorGalleryControlSession?.playerControl}
            currentVideoPlayback={currentVideoPlayback}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default connect(({ firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
}))(FloorPlateGallery)
