import { FireStoreMap, ProjectIdentity } from '@store/types'

interface FirebaseQueryInterface {
  collection: string
  doc: string
  storeAs: string
}

export interface FirebaseQueryCollectionInterface
  extends FirebaseQueryInterface {
  subcollections?: Omit<FirebaseQueryInterface, 'storeAs'>[]
}

const getSession = (firestore: FireStoreMap) => {
  const { status } = firestore
  if (firestore?.data.devsuite && status.requested.devsuite) {
    return firestore?.data.devsuite
  }
  return undefined
}

const generateId = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const setFirebaseQuery = (projectIdentity: ProjectIdentity) => {
  const FirebaseQuery: FirebaseQueryCollectionInterface[] = []
  if (projectIdentity) {
    const { projectId, sessionId } = projectIdentity
    if (projectId && sessionId) {
      return [
        ...FirebaseQuery,
        {
          collection: 'devsuite',
          doc: projectId,
          subcollections: [{ collection: 'session', doc: sessionId }],
          storeAs: 'devsuite',
        },
      ]
    }
  }
  return FirebaseQuery
}

export { getSession, generateId }
