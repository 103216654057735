import React from 'react'

import AssetHandler from '@utilities/asset-handler'

interface ComponentPropsInterface {
  type: string
}

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

const DeselectedView = ({ type }: ComponentPropsInterface) => {
  const humanReadableKey = () => {
    let readable = type
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim()

    if (readable.endsWith('s')) {
      readable = readable.slice(0, -1)
    }

    return readable
  }

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center space-y-2 bg-[#EEEEEE]">
      <img
        className="absolute left-0 top-0 z-1 h-full w-full object-contain"
        alt="thumbnail"
        src={AssetHandler({
          url: String(PLACEHOLDER_IMAGE),
          type: 'new',
          staticUrl: true,
        })}
      />
      {type && type !== 'floorplan' ? (
        <>
          <div className="z-2 text-center text-2xl font-bold">{`No ${humanReadableKey()} selected`}</div>
          <div className="z-2 text-center text-xl font-medium">
            Please select an option
          </div>
        </>
      ) : null}
    </div>
  )
}

export default DeselectedView
