import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

export const statusIndicatorSlice = createSlice({
  name: 'statusIndicator',
  initialState: {
    isOnline: true,
    isLoading: false,
  },
  reducers: {
    setOnlineStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      ...{ isOnline: action.payload },
    }),
    setLoadingStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      ...{ isLoading: action.payload },
    }),
  },
})

export const { setOnlineStatus, setLoadingStatus } =
  statusIndicatorSlice.actions

export const selectStatusIndicator = (state: RootStateTypeExtra) =>
  state.statusIndicator

export default statusIndicatorSlice.reducer
