import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  RootStateTypeExtra,
  ShortlistItemInterface,
  ShortlistPropertyType,
} from '@store/types'

export interface ShortlistState {
  shortlists: ShortlistItemInterface[]
}

const INITIAL_STATE = {
  shortlists: [],
} as ShortlistState

const shortlistSlice = createSlice({
  name: 'shortlist',
  initialState: () => {
    const initialState = { ...INITIAL_STATE }

    return initialState
  },
  reducers: {
    addShortlist: (
      state: ShortlistState,
      action: PayloadAction<ShortlistItemInterface>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: [...state.shortlists, action.payload],
      }
      return newState
    },
    updateShortlist: (
      state: ShortlistState,
      action: PayloadAction<{
        propertyId: ShortlistItemInterface['propertyId']
        data: Partial<ShortlistItemInterface>
      }>
    ) => {
      const newShortlists = [...state.shortlists]
      const matchedIndex = newShortlists.findIndex(
        (item) => item.propertyId === action.payload.propertyId
      )
      if (matchedIndex >= 0) {
        newShortlists[matchedIndex] = {
          ...newShortlists[matchedIndex],
          ...(action.payload.data as ShortlistItemInterface),
          propertyId: action.payload.propertyId,
        }
      }
      const newState: ShortlistState = {
        ...state,
        shortlists: newShortlists,
      }
      return newState
    },
    setShortlists: (
      state: ShortlistState,
      action: PayloadAction<ShortlistItemInterface[]>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: action.payload,
      }
      return newState
    },
    filterShortlist: (
      state: ShortlistState,
      action: PayloadAction<{ propertyId: string; type: ShortlistPropertyType }>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: state.shortlists?.filter((item: ShortlistItemInterface) => {
          if (action.payload.propertyId !== item.propertyId) {
            return true
          }
          return false
        }),
      }
      return newState
    },
    removeShortlist: () => ({
      ...INITIAL_STATE,
    }),
  },
})

export const {
  addShortlist,
  updateShortlist,
  setShortlists,
  filterShortlist,
  removeShortlist,
} = shortlistSlice.actions

export const selectShortlist = (state: RootStateTypeExtra) => state.shortlist

export default shortlistSlice.reducer
