import { useFirestore } from 'react-redux-firebase'

import type { ProjectIdentity, SessionMap } from '@store/types'

export interface FirebaseControlQueryProps {
  projectIdentity: ProjectIdentity
}

export interface FirebaseControlQueryReturn {
  getMap: () => Promise<SessionMap | undefined>
  update: (arg: { [key: string]: any }) => Promise<any>
  refreshSession: (sessionId?: string, projectId?: string) => Promise<any>
  updateCollection: (collection: string, value: any) => Promise<any>
  isProjectEnabled: (projectId: string) => Promise<boolean>
  doesProjectExist: (projectId: string) => Promise<boolean>
  doesSessionExist: (sessionId: string, projectId: string) => Promise<boolean>
  updateRoute: (
    activeRoute: string,
    sessionId?: string,
    projectId?: string
  ) => Promise<any>
  updateConnection: (
    connected: boolean,
    sessionId?: string,
    projectId?: string
  ) => Promise<any>
  updateSessionOwner: (
    sessionOwners: Array<{
      name: string
      email: string
      lastUpdate: string
    }>,
    sessionId?: string,
    projectId?: string
  ) => Promise<any>
  updateCollectionBySessionId: (
    sessionId: string,
    collection: string,
    value: any
  ) => Promise<any>
}

const FirebaseControlQuery = ({
  projectIdentity,
}: FirebaseControlQueryProps): FirebaseControlQueryReturn => {
  const firestore = useFirestore()

  const isProjectEnabled = async (projectName: string) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectName)
      .get()
    const { exists } = response
    if (!exists) {
      return false
    }
    const data = response.data()
    if (!data) {
      return true
    }
    if (!('enable' in data)) {
      return true
    }
    return data?.enable ?? false
  }

  const doesProjectExist = async (projectId: string) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectId)
      .get()
    return exists
  }

  const doesSessionExist = async (sessionId: string, projectId: string) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectId)
      .collection('session')
      .doc(sessionId)
      .get()
    return exists
  }

  const getMap = async () => {
    const data = await firestore.collection('session-base').doc('map').get()
    if (!data.exists) {
      return undefined
    }
    return data.data() as SessionMap
  }

  const updateConnection = async (
    connected: boolean,
    sessionId?: string,
    projectId?: string
  ) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectId || projectIdentity.projectId)
      .collection('session')
      .doc(
        (sessionId || projectIdentity.sessionId)
          .toUpperCase()
          .replace(/\s/g, '')
      )
      .update({
        connected,
      })
    return response
  }

  const updateRoute = async (
    activeRoute: string,
    sessionId?: string,
    projectId?: string
  ) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectId || projectIdentity.projectId)
      .collection('session')
      .doc(sessionId || projectIdentity.sessionId)
      .update({ activeRoute })
    return response
  }

  const refreshSession = async (sessionId?: string, projectId?: string) => {
    const map = await getMap()
    const response = await firestore
      .collection('devsuite')
      .doc(projectId || projectIdentity.projectId)
      .collection('session')
      .doc(sessionId || projectIdentity.sessionId)
      .update({ ...(map || {}), connected: true })
    return response
  }

  const updateSessionOwner = (
    sessionOwners: Array<{
      name: string
      email: string
      lastUpdate: string
    }>,
    sessionId?: string,
    projectId?: string
  ) => {
    const response = firestore
      .collection('devsuite')
      .doc(projectId || projectIdentity.projectId)
      .collection('session')
      .doc(sessionId || projectIdentity.sessionId)
      .update({ sessionOwners })
    return response
  }

  const updateCollection = async (collection: string, value: any) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update({
        [collection]: value,
      })
    return response
  }

  const update = async (value: { [key: string]: any }) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update(value)
    return response
  }

  const updateCollectionBySessionId = async (
    sessionId: string,
    collection: string,
    value: any
  ) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(sessionId)
      .update({
        [collection]: value,
      })
    return response
  }

  return {
    getMap,
    update,
    updateRoute,
    refreshSession,
    updateConnection,
    updateCollection,
    doesProjectExist,
    isProjectEnabled,
    doesSessionExist,
    updateSessionOwner,
    updateCollectionBySessionId,
  }
}

export default FirebaseControlQuery
