import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryInterface, GalleryItemInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface VideoGalleryData {
  id: string
  title: string
  fileName: string
  src: string
  previewImageSrc: string
}

type VideoGalleryType = {
  projectName: string
}

export const videoGalleryApi = createApi({
  reducerPath: 'videoGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getVideoGallery: builder.query<
      ApiResponse<Array<VideoGalleryData>>,
      VideoGalleryType
    >({
      query: (params: VideoGalleryType) => ({
        url: `/v1/${params.projectName}/videos${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processGalleryData = (
  data: Array<VideoGalleryData>
): Array<GalleryInterface> => {
  const galleryItems: Array<GalleryItemInterface> = []
  data.forEach((item: VideoGalleryData) => {
    galleryItems.push({
      id: item.id,
      title: item?.title || item.fileName,
      imageSource: item.previewImageSrc,
      videoSource: item.src,
    })
  })

  return galleryItems.length > 0 ? [{ items: galleryItems }] : []
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  videoGalleryData: GalleryInterface[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  videoGalleryData: processGalleryData(data?.data || []),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetVideoGalleryQuery } = videoGalleryApi
