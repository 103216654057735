import React from 'react'

interface AbsoluteContainerProps {
  children: React.ReactNode
}

const AbsoluteContainer = ({ children }: AbsoluteContainerProps) => (
  <div className="absolute h-full w-full">{children}</div>
)

export default AbsoluteContainer
