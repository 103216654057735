import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

interface AccessInterface {
  id: string
  email: string
}

interface SessionKeyInterface {
  projectId: string
  key: string
}

export interface MasterKeyData {
  id: string
  masterKey: string
  label: string
  access: AccessInterface[]
  sessionKeys: SessionKeyInterface[]
}

type MasterKeyType = {
  masterKey: string
  userId: string
}

export const masterKeyApi = createApi({
  reducerPath: 'masterKeyApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getMasterKey: builder.query<
      ApiResponse<Array<MasterKeyData>>,
      MasterKeyType
    >({
      query: (params: MasterKeyType) => ({
        url: `/v2/master-keys/${params.masterKey}/${params.userId}`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  masterKeyData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetMasterKeyQuery } = masterKeyApi
