import React from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { connect, useDispatch } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useLocation } from 'react-router-dom'
import { compose } from 'redux'

import Routes from '@src/routes'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { TokenInterface } from '@store/actionSlices/token'
import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionListContent,
  SessionMap,
} from '@store/types'

import IdleTimeHandler from '@components/idle-time-handler'
import LoadingIndicator from '@components/loading-indicator'

import {
  MasterKeyData,
  selectFromResult,
  useGetMasterKeyQuery,
} from '@api/master-key'
import { useLazyGetProjectListQuery } from '@api/project-list'

import useConnectionIndicator from '@utilities/connection-indicator'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import { setFirebaseQuery } from '@utilities/firebase-util'
import { getQueryStringParams } from '@utilities/helper'
import RefreshHandler from '@utilities/refresh-handler'
import styleUtil from '@utilities/style-util'
import { hasToken } from '@utilities/token-helper'
import TokenRenewalHandler from '@utilities/token-renewal-handler'

interface FirebaseStateType extends RootStateFirebase {
  devsuite: SessionMap
  projectIdentity: ProjectIdentity
  token: TokenInterface
  isOnline: boolean
  remoteRefreshTriggerKey: string
}

const { REACT_APP_DATA_POLLING_INTERVAL_X_SECONDS } = process.env

const App = ({
  devsuite: session,
  projectIdentity,
  token,
  isOnline,
  remoteRefreshTriggerKey,
}: FirebaseStateType) => {
  styleUtil(projectIdentity.theme)
  const location = useLocation()

  const dispatch = useDispatch()

  useConnectionIndicator({ token, isOnline })
  TokenRenewalHandler({ token })

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const { masterKey, sessionId } = projectIdentity
  const { _id: userId } = token
  const skipMasterKeyQuery = React.useMemo(
    () => !(hasToken(token) && masterKey && sessionId),
    [token, masterKey, sessionId]
  )
  const masterKeyPayload = useGetMasterKeyQuery(
    {
      masterKey,
      userId,
    },
    {
      selectFromResult,
      skip: skipMasterKeyQuery,
      pollingInterval:
        Number(REACT_APP_DATA_POLLING_INTERVAL_X_SECONDS || 10) * 1000,
    }
  )

  const [getProjectListTrigger] = useLazyGetProjectListQuery()

  RefreshHandler({
    refreshTriggerKeyFirebase: session?.remoteRefreshTriggerKey ?? '',
    refreshTriggerKeyLocal: remoteRefreshTriggerKey,
  })

  const handleClientCheck = (client: boolean) => {
    if (!client) {
      firebaseControlQuery.updateCollection('client.remote', true)
    }
  }

  const handleMasterSessionKeys = React.useCallback(
    async (argMasterKeyData: MasterKeyData) => {
      const { sessionKeys: masterSessionKeys } = argMasterKeyData
      if (masterSessionKeys.length < 1) {
        return
      }
      const filteredMasterSessionKeys = masterSessionKeys.filter(
        (item) =>
          !projectIdentity.disconnectedSessions?.find(
            (i) => i.projectId === item.projectId && i.sessionId === item.key
          )
      )

      const promise = await Promise.all(
        filteredMasterSessionKeys.map(async (sess) => {
          const response = await getProjectListTrigger({
            email: token.email,
            page: 1,
            query: sess.projectId.replace(/[^a-zA-Z ]/g, ' '),
          }).unwrap()

          const project = response?.data?.lists?.[0]

          if (project) {
            await firebaseControlQuery.updateConnection(
              true,
              sess.key,
              sess.projectId
            )
            return {
              projectId: sess.projectId,
              projectName: project.title,
              projectUUID: project.id,
              sessionId: sess.key,
              sessionName: '',
            }
          }
          return undefined
        })
      )

      const sessionList = promise.filter((res) => res)

      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          sessionList: sessionList as SessionListContent[],
        })
      )
    },
    [masterKeyPayload]
  )

  React.useEffect(() => {
    const { masterKeyData, isError, status } = masterKeyPayload
    if (
      isError ||
      status !== 'fulfilled' ||
      !Object.keys(masterKeyData).length
    ) {
      return
    }
    handleMasterSessionKeys(masterKeyData)
  }, [masterKeyPayload])

  React.useEffect(() => {
    if (session) {
      const {
        client: { remote },
      } = session
      handleClientCheck(remote)
    }
  }, [session])

  React.useEffect(() => {
    const searchQuery = getQueryStringParams(location.search)

    const {
      disconnect: previousDisconnectState,
      showClearCacheOption: previousClearCacheState,
      searchQuery: previousSearchQuery,
    } = projectIdentity

    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        disconnect: searchQuery?.disconnect
          ? searchQuery?.disconnect === 'true'
          : previousDisconnectState,
        searchQuery: searchQuery?.searchQuery
          ? searchQuery?.searchQuery === 'true'
          : previousSearchQuery,
        showClearCacheOption: searchQuery?.clearCache
          ? searchQuery?.clearCache === 'true'
          : previousClearCacheState,
      })
    )
  }, [])

  return (
    <>
      <ReactNotifications />
      <Routes token={token} />
      <IdleTimeHandler />
      <LoadingIndicator />
    </>
  )
}

export default compose(
  connect(
    ({
      projectIdentity,
      firestore: {
        data: { devsuite },
      },
      statusIndicator: { isOnline },
      token,
      appConfig: { remoteRefreshTriggerKey },
    }: RootStateFirebase) => ({
      devsuite,
      projectIdentity,
      token,
      isOnline,
      remoteRefreshTriggerKey,
    })
  ),
  firestoreConnect(({ projectIdentity }: RootStateFirebase) =>
    setFirebaseQuery(projectIdentity)
  )
)(App) as React.ComponentType
