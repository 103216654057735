import React from 'react'

interface HorizontalScrollContainerProps {
  children: React.ReactNode
  className?: string
}

const HorizontalScrollContainer = React.forwardRef(
  ({ children, className }: HorizontalScrollContainerProps, ref) => {
    const sliderRef = React.useRef<HTMLDivElement | null>(null)
    const dragState = React.useRef({
      clicked: false,
      dragging: false,
      position: 0,
    })

    const isDragging = () => dragState.current.dragging

    React.useImperativeHandle(ref, () => ({
      isDragging,
    }))

    const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
      dragState.current.clicked = true
      dragState.current.position = e.clientX
    }

    const stopDragging = () => {
      window.requestAnimationFrame(() => {
        dragState.current.dragging = false
        dragState.current.clicked = false
      })
    }

    const handleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
      const newDiff = dragState.current.position - e.clientX

      const movedEnough = Math.abs(newDiff) > 5

      if (dragState.current.clicked && movedEnough) {
        dragState.current.dragging = true
      }
      if (dragState.current.dragging && movedEnough) {
        dragState.current.position = e.clientX
        if (sliderRef.current) {
          sliderRef.current.scrollLeft += newDiff
        }
      }
    }

    return (
      <div
        className={`no-scrollbar flex overflow-x-auto ${className}`}
        role="button"
        tabIndex={0}
        onMouseMove={handleDrag}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
        ref={sliderRef}
      >
        {children}
      </div>
    )
  }
)

HorizontalScrollContainer.displayName = 'HorizontalScrollContainer'

export default HorizontalScrollContainer
