import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'

import {
  selectFromResult as selectFromLocationResult,
  useGetLocationQuery,
} from '@api/location'

import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectName: string
}

const Location = ({ session, projectName }: PagePropsInterface) => {
  const locationPayload = useGetLocationQuery(
    { projectName },
    { selectFromResult: selectFromLocationResult }
  )

  return (
    <Container>
      <div className="h-full w-full px-4">
        <div className="flex h-top-bar w-full items-center justify-center text-neutralColour">
          <div className="text-title font-medium">Location Map</div>
        </div>
        <div className="no-scrollbar h-page-body w-full overflow-auto">
          <DataHandler
            payload={{ ...locationPayload, data: locationPayload.locationData }}
          >
            <GalleryHandler
              galleryName="location"
              galleries={locationPayload.locationData}
              gallerySession={session?.location}
              previousRoute={String(session?.previousRoute)}
            />
          </DataHandler>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity: { projectId }, firestore }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName: projectId,
  })
)(Location)
