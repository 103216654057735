import React from 'react'

import { SvgProps } from './types'

const VolumeAddSvg = ({
  width,
  height,
  className,
  size,
  styles,
  stroke,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '42'}
      height={height || '32'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 42 32"
      stroke="currentColor"
    >
      <path
        d="M1.75 19.2497V12.7502C1.75 11.822 2.11875 10.9317 2.77513 10.2753C3.4315 9.61896 4.32174 9.25021 5.25 9.25021H10.325C10.6672 9.25013 11.0018 9.14974 11.2875 8.96146L21.7875 2.03846C22.0513 1.86472 22.3572 1.76563 22.6728 1.7517C22.9884 1.73777 23.3019 1.80952 23.58 1.95934C23.8581 2.10915 24.0904 2.33145 24.2524 2.60264C24.4144 2.87384 24.4999 3.18382 24.5 3.49971V28.5002C24.4999 28.8161 24.4144 29.1261 24.2524 29.3973C24.0904 29.6685 23.8581 29.8908 23.58 30.0406C23.3019 30.1904 22.9884 30.2622 22.6728 30.2482C22.3572 30.2343 22.0513 30.1352 21.7875 29.9615L11.2875 23.0385C11.0018 22.8502 10.6672 22.7498 10.325 22.7497H5.25C4.32174 22.7497 3.4315 22.381 2.77513 21.7246C2.11875 21.0682 1.75 20.178 1.75 19.2497Z"
        stroke="currentColor"
        strokeWidth={stroke || '3.25'}
      />
      <path
        d="M30.625 8.125C30.625 8.125 33.25 10.75 33.25 15.125C33.25 19.5 30.625 22.125 30.625 22.125M35.875 2.875C35.875 2.875 40.25 7.25 40.25 15.125C40.25 23 35.875 27.375 35.875 27.375"
        stroke="currentColor"
        strokeWidth={stroke || '3.25'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VolumeAddSvg
