import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra, User } from '@store/types'

const user = localStorage.getItem('user')

let initialState = {
  name: '',
  email: '',
} as User

if (user) {
  initialState = JSON.parse(user)
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: User, action: PayloadAction<User>) => {
      localStorage.setItem('user', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload,
      }
    },
    removeUser: (state: User) => {
      localStorage.removeItem('user')
      return {
        ...state,
        ...{
          name: '',
          email: '',
        },
      }
    },
  },
})

export const { setUser, removeUser } = userSlice.actions

export const selectProject = (state: RootStateTypeExtra) => state.user

export default userSlice.reducer
