import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  BackgroundInterface,
  BedConfigInterface,
  HouseAndLandConfigurationInterface,
  LogoInterface,
  MenuItemInterface,
  PriceRange,
  ProjectType,
  RemoteInteractiveBuildingInterface,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

interface SnaploaderInterface {
  active: boolean
  sceneSnapId: string
  sceneModelId: string
}

interface EnvisionVRConfigurationInterface {
  active: boolean
  url: string
}

interface ThemeConfigInterface {
  font: string
  mainColour: string
  neutralColour: string
  secondaryColour: string
  tertiaryColour: string
  remoteBackground: BackgroundInterface
  remoteVisionBackground: BackgroundInterface
  remoteVisionLogo: string
}

interface SlideShowInterface {
  type: string
  intervalInSeconds: number
}

interface GallerySettingsInterface {
  slideShow: SlideShowInterface
}

interface ProjectInterface {
  logo: LogoInterface
  longhand: boolean
  showPrice: boolean
  hide_sold_price: boolean
  show_carousel_indicator: boolean
  carousel_indicator_location: string
  hideFilter: boolean
  onlyShowUnitGalleries: boolean
  snaploader: SnaploaderInterface
}

interface ConfigInterface {
  config: {
    project: ProjectInterface
  }
  prices: PriceRange
  bedConfig: Array<BedConfigInterface>
  aspects: Array<string>
  unitTypes: Array<string>
  envisionVr: EnvisionVRConfigurationInterface
  theme: ThemeConfigInterface
  navigationSettings: Array<MenuItemInterface>
  remoteInteractiveBuilding: RemoteInteractiveBuildingInterface
  gallerySettings: GallerySettingsInterface
  disableSoldUnit: boolean
  type: ProjectType
  houseAndLandConfig: HouseAndLandConfigurationInterface
}

type ConfigType = {
  projectName: string
}

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getConfig: builder.query<ApiResponse<ConfigInterface>, ConfigType>({
      query: (params: ConfigType) => ({
        url: `/v1/${params.projectName}/config`,
        method: 'get',
      }),
    }),
  }),
})

const snaploaderState = (snaploaderData: SnaploaderInterface): boolean => {
  const active = snaploaderData?.active || false
  const sceneSnapId = snaploaderData?.sceneSnapId || ''
  const sceneModelId = snaploaderData?.sceneModelId || ''

  return active === true && sceneSnapId !== '' && sceneModelId !== ''
}

const envisionVRState = (
  envisionVRData: EnvisionVRConfigurationInterface
): boolean => {
  const active = envisionVRData?.active || false
  const url = envisionVRData?.url || ''

  return active === true && url !== ''
}

const processConfigData = (configResponse: ConfigInterface) => {
  const {
    config,
    prices,
    theme,
    bedConfig,
    aspects,
    unitTypes,
    navigationSettings,
    remoteInteractiveBuilding,
    envisionVr,
    gallerySettings,
    disableSoldUnit,
    type: projectType,
    houseAndLandConfig,
  } = configResponse

  let projectData = {} as ProjectInterface

  if (config?.project !== undefined) {
    projectData = config.project
  }

  const {
    showPrice,
    hide_sold_price: hideSoldPrice,
    longhand,
    onlyShowUnitGalleries,
    snaploader,
    logo,
  } = projectData

  return {
    showPrice,
    hideSoldPrice,
    longhand,
    onlyShowUnitGalleries,
    isSnaploaderActive: snaploaderState(snaploader),
    isEnvisionVRActive: envisionVRState(envisionVr),
    logo: logo || ({} as LogoInterface),
    background: theme?.remoteBackground || ({} as BackgroundInterface),
    visionBackground:
      theme?.remoteVisionBackground || ({} as BackgroundInterface),
    visionLogo: theme?.remoteVisionLogo || '',
    theme: theme || {},
    prices: prices || ({} as PriceRange),
    bedConfig: bedConfig || [],
    aspects: aspects || [],
    unitTypes: unitTypes || [],
    gallerySettings: gallerySettings || {},
    navigationSettings: navigationSettings || [],
    remoteInteractiveBuilding: remoteInteractiveBuilding || {},
    disableSoldUnit: disableSoldUnit || false,
    type: projectType || ProjectType.Apartment,
    houseAndLandConfig: houseAndLandConfig || {},
  }
}

export const selectFromResult = ({
  data,
  status,
  isError,
}: UseQueryStateResult<any, any>) => ({
  configData: processConfigData(data?.data || {}),
  status,
  isError,
  isLoaded: status === 'fulfilled',
})

export const { useGetConfigQuery } = configApi
