import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

import useClearCache from '@hooks/useClearCache'

export type ErrorBoundaryFallbackProps = Partial<FallbackProps> & {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const ErrorBoundaryFallback = ({
  projectIdentity,
  session,
  resetErrorBoundary,
}: ErrorBoundaryFallbackProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [isCacheCleared, handleClearCache] = useClearCache({
    projectIdentity,
    session,
  })

  const onClearCache = () => {
    handleClearCache()
  }

  React.useEffect(() => {
    if (isCacheCleared) {
      resetErrorBoundary?.()
      firebaseControlQuery
        .update({
          [`activeRoute`]: 'vision',
        })
        .then(() => {
          window.location.reload()
        })
    }
  }, [isCacheCleared])

  return (
    <Container>
      <div className="flex h-full w-full flex-col justify-center p-4">
        <div className="flex flex-col items-center justify-center rounded-full bg-secondaryColour p-16">
          <h3 className="mb-6 text-center text-5xl font-bold">
            New updates available!
          </h3>
          <p className="mb-6 text-center text-2xl">
            It looks like this page hasn&apos;t loaded correctly, possibly due
            to recent updates. Please go back to the main page or use the menu
            to explore other sections. Alternatively, click below to refresh.
          </p>
          <div>
            <button
              type="button"
              className="rounded-lg bg-tertiaryColour px-4 py-2 text-xl text-neutralColour"
              onClick={onClearCache}
            >
              Refresh Now
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  projectIdentity,
  session: getSession(firestore),
}))(ErrorBoundaryFallback)
