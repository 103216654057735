import React from 'react'
import { connect } from 'react-redux'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateFirebase,
  ShortlistPropertyType,
} from '@store/types'

import { Modal } from '@components/modals'

import { LotInterface } from '@api/houseAndLand'

import ShortlistHandler from '@utilities/shortlist-handler'

import { CheckSvg, StarSvg } from '@svg/react'

type ShortlistButtonProps = {
  activeLotData?: LotInterface
  activePrecinctId: string
  activeStageId: string
  activePackageConfiguration: PackageConfigurationInterface
  projectIdentity: ProjectIdentity
  shortlist: ShortlistState
}

const ShortlistButton = ({
  activeLotData,
  activePrecinctId,
  activeStageId,
  activePackageConfiguration,
  projectIdentity,
  shortlist,
}: ShortlistButtonProps) => {
  const [showConfirmModal, toggleConfirmModal] = React.useState<boolean>(false)
  const [removeShortlistProperty, setRemoveShortlistProperty] =
    React.useState<boolean>(false)
  const [showAlertModal, toggleAlertModal] = React.useState<boolean>(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState<string>('')

  const property = React.useMemo<LotShortlistInterface | undefined>(
    () => ({
      propertyId: activeLotData?.id || '',
      propertyName: activeLotData?.name || '',
      references: {
        precinctId: activePrecinctId,
        stageId: activeStageId,
      },
      configuration: activePackageConfiguration,
      type: ShortlistPropertyType.Lot,
    }),
    [activeLotData, activePrecinctId, activeStageId, activePackageConfiguration]
  )
  const [isShortlisted, handleShortlistClick] =
    (property &&
      ShortlistHandler({
        property,
        shortlist,
        setShortlistErrorMessage: (message) => {
          setAlertModalMessage(message)
          toggleAlertModal(true)
        },
        projectIdentity,
        toggleConfirmModal,
        removeShortlistProperty,
        setRemoveShortlistProperty,
        available: activeLotData?.status || '',
      })) ||
    []

  return (
    <>
      <button
        className={`relative w-full overflow-hidden rounded-lg px-4 py-2.5 text-base font-bold text-mainColour ipad-mini-land:px-6 ipad-mini-land:py-[18px] ipad-mini-land:text-lg ${
          isShortlisted ? '' : 'shadow-[0px_0px_12px_0px_rgba(0,0,0,0.25)]'
        }`}
        type="button"
        onClick={handleShortlistClick}
      >
        {isShortlisted ? (
          <span className="relative z-2 flex items-center justify-center gap-2 uppercase text-white">
            <CheckSvg
              size="m"
              className="h-6 w-6 stroke-white text-white transition-all"
              stroke={1}
            />
            Added to Shortlist
          </span>
        ) : (
          <span className="relative z-2 flex items-center justify-center gap-2 uppercase text-mainColour">
            <StarSvg
              size="m"
              className="h-6 w-6 stroke-mainColour text-transparent transition-all"
              stroke={2}
            />
            Save to Shortlist
          </span>
        )}
        <div
          className={`absolute bottom-0 left-0 z-1 h-full w-full rounded-md border-[3px] border-mainColour backdrop-blur ${
            isShortlisted
              ? 'border-none bg-gradient shadow-standard'
              : 'bg-mainColour/20 '
          }`}
        ></div>
      </button>

      <Modal
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
        modalWidth="max-w-lg"
        title="Message"
      />

      <Modal
        isVisible={showConfirmModal}
        toggleModal={toggleConfirmModal}
        message="Do you want to remove this item from the shortlist?"
        modalWidth="max-w-lg"
        title="Confirmation"
        applyButtonText="Yes"
        closeButtonText="No"
        handleApply={() => {
          setRemoveShortlistProperty(true)
        }}
      />
    </>
  )
}

export default connect(({ projectIdentity, shortlist }: RootStateFirebase) => ({
  projectIdentity,
  shortlist,
}))(ShortlistButton)
