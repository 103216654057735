import React from 'react'

import { SvgProps } from './types'

const FastForwardSvg = ({ width, height, className }: SvgProps) => (
  <svg
    width={width || '15'}
    height={height || '17'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 37 40"
    fill="none"
  >
    <path
      d="M34.4 21.8C34.4 25.0041 33.4499 28.1362 31.6698 30.8002C29.8897 33.4643 27.3596 35.5407 24.3995 36.7668C21.4393 37.993 18.182 38.3138 15.0395 37.6887C11.897 37.0636 9.01049 35.5207 6.74488 33.2551C4.47927 30.9895 2.93637 28.1029 2.31129 24.9605C1.68621 21.818 2.00702 18.5607 3.23316 15.6005C4.4593 12.6404 6.53569 10.1103 9.19977 8.33019C11.8638 6.55011 14.9959 5.6 18.2 5.6H31.7M31.7 5.6L28.1 2M31.7 5.6L28.1 9.2M12.8 14.6V27.2"
      stroke="currentColor"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.6002 14.6001H20.0002C19.5228 14.6001 19.065 14.7897 18.7274 15.1273C18.3898 15.4649 18.2002 15.9227 18.2002 16.4001V19.1001C18.2002 19.5775 18.3898 20.0353 18.7274 20.3729C19.065 20.7105 19.5228 20.9001 20.0002 20.9001H21.8002C22.2776 20.9001 22.7354 21.0897 23.073 21.4273C23.4106 21.7649 23.6002 22.2227 23.6002 22.7001V25.4001C23.6002 25.8775 23.4106 26.3353 23.073 26.6729C22.7354 27.0105 22.2776 27.2001 21.8002 27.2001H18.2002"
      stroke="currentColor"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FastForwardSvg
