import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { TokenInterface as TokenPayloadInterface } from '@store/actionSlices/token'
import { User } from '@store/types'

import { ApiResponse } from '@api/types/api-response.type'

export interface TokenUserPayloadInterface
  extends User,
    TokenPayloadInterface {}

export interface ProjectPayload {
  name: string
  id: string
  canonical_name: string
}

export interface Auth {
  user: TokenUserPayloadInterface
  project: ProjectPayload
}

export interface RefreshToken {
  user: User | TokenPayloadInterface
}

type AuthType = {
  password: string
  email: string
  project_canonical_name: string
}

type AuthToken = {
  token: string
  projectId: string
}

type RefreshTokenType = {
  refresh_token: string
  email: string
}

interface ClientInformation {
  host: string
  userAgent: string
  platform: string
  device: string
  browser: string
  screen: string
  viewport: string
  language: string
  cookie: string
  geoLocation: {
    lat: number
    lng: number
  } | null
  client: 'showcase' | 'remote' | 'dashboard' | 'buyers-portal'
}

type ClientInformationParam = {
  clientInformation: ClientInformation
}

const baseUrl = process.env.REACT_APP_API_URL
const client_id = process.env.REACT_APP_CLIENT_ID
const client_secret = process.env.REACT_APP_CLIENT_SECRET

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    authUser: builder.mutation<
      ApiResponse<Auth>,
      AuthType & ClientInformationParam
    >({
      query: ({
        clientInformation,
        ...params
      }: AuthType & ClientInformationParam) => ({
        url: '/v1/user/login',
        method: 'post',
        body: {
          ...{
            client_id,
            client_secret,
          },
          ...params,
        },
        headers: {
          'AD-LOGIN-ENVIRONMENT': JSON.stringify(clientInformation),
        },
      }),
    }),
    authViaToken: builder.mutation<
      ApiResponse<Auth>,
      AuthToken & ClientInformationParam
    >({
      query: ({
        clientInformation,
        token,
        projectId,
      }: AuthToken & ClientInformationParam) => ({
        url: '/v1/user/token/auth',
        method: 'post',
        body: {
          ...{
            client_id,
            client_secret,
          },
          ...{ auth_code: token, project_canonical_name: projectId },
        },
        headers: {
          'AD-LOGIN-ENVIRONMENT': JSON.stringify(clientInformation),
        },
      }),
    }),
    renewUserToken: builder.mutation<
      ApiResponse<RefreshToken>,
      RefreshTokenType
    >({
      query: ({ refresh_token, email }: RefreshTokenType) => ({
        url: '/v1/user/refresh-token',
        method: 'post',
        body: {
          ...{
            client_id,
            client_secret,
          },
          ...{ refresh_token },
        },
        headers: {
          user: email,
        },
      }),
    }),
  }),
})

export const {
  useAuthUserMutation,
  useAuthViaTokenMutation,
  useRenewUserTokenMutation,
} = userApi
