import React from 'react'

import { ArrowSvg } from '@svg/react'

interface WithCarouselControlProps {
  children: React.ReactNode
  activeItemIndex: number
  onChangeActiveItemIndex: (index: number) => void
  itemsCount: number
  rounded?: boolean
  hideControls?: boolean
}

const WithCarouselControl = ({
  children,
  activeItemIndex,
  onChangeActiveItemIndex,
  itemsCount,
  rounded,
  hideControls,
}: WithCarouselControlProps) => {
  const disablePrevious = activeItemIndex < 1
  const disableNext = activeItemIndex > itemsCount - 2
  const disableClassName = 'cursor-not-allowed opacity-70'
  const showControls = itemsCount < 2 || hideControls

  return (
    <div className="relative flex h-full w-full items-center justify-between">
      <button
        className={`z-10 flex items-center justify-center bg-gradient shadow-standard ${
          rounded ? 'mx-2 h-16 w-16 rounded-full' : 'rounded-r-lg px-1 py-10'
        } ${disablePrevious ? disableClassName : ''} ${
          showControls ? 'hidden' : ''
        }`}
        type="button"
        onClick={() => onChangeActiveItemIndex(activeItemIndex - 1)}
        disabled={disablePrevious}
      >
        <ArrowSvg className="h-11 w-11" strokeColor="#FFF7E9" />
      </button>

      {children}

      <button
        className={`z-10 flex items-center justify-center bg-gradient shadow-standard ${
          rounded ? 'mx-2 h-16 w-16 rounded-full' : 'rounded-l-lg px-1 py-10'
        } ${disableNext ? disableClassName : ''} ${
          showControls ? 'hidden' : ''
        }`}
        type="button"
        onClick={() => onChangeActiveItemIndex(activeItemIndex + 1)}
        disabled={disableNext}
      >
        <ArrowSvg rotate="right" className="h-11 w-11" strokeColor="#FFF7E9" />
      </button>
    </div>
  )
}

export default WithCarouselControl
