import React from 'react'

interface ComponentPropsInterface {
  label: string
  handleClick: () => void
}

const BlockCard = ({ label, handleClick }: ComponentPropsInterface) => (
  <button
    onClick={handleClick}
    type="button"
    className="cursor-hand mb-[15px] w-full rounded-lg bg-gradient py-10 text-heading font-medium text-neutralColour shadow-standard"
  >
    {label}
  </button>
)

export default BlockCard
