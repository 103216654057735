import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { removeShortlist } from '@store/actionSlices/shortlist'
import { ProjectIdentity, RootStateFirebase } from '@store/types'

import { Modal } from '@components/modals'

import { Pagination, ProjectListDetails } from '@api/project-list'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import Throttle from '@utilities/throttle'

import { ArrowRefreshSvg, ChevronUpDownSvg } from '@svg/react'

import constants from './constants'

const ProjectSwitchHandler = ({
  projectIdentity,
  projectList,
  pagination,
  isActive,
  setPage,
  setProjectQuery,
  pageCount,
  activeRouteFirebase,
  currentPage,
}: {
  projectIdentity: ProjectIdentity
  projectList: Array<ProjectListDetails>
  pagination: Pagination
  isActive: boolean
  setPage: (status: 'prev' | 'next') => void
  pageCount: number
  setProjectQuery: (query: string) => void
  activeRouteFirebase?: string
  currentPage: number
}) => {
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const dispatch = useDispatch()
  const [isModalActive, modalToggle] = React.useState(false)

  const [canonicalName, projectCanonicalName] = React.useState('')

  const query = Throttle(canonicalName, 1000)

  const triggerFirebaseProjectSwitch = async ({
    projectId,
    projectLabel,
    projectName,
  }: {
    projectId: string
    projectLabel: string
    projectName: string
  }) => {
    await firebaseControlQuery.update({
      [`projectSwitch`]: {
        projectId,
        projectLabel,
        projectName,
      },
    })
  }

  const getProject = (prjct: ProjectListDetails) => {
    const newSessionList = projectIdentity.sessionList.map((res) => {
      if (res.projectId === prjct.titleCanonical) {
        return {
          ...res,
          projectName: res.projectName || prjct.title,
          projectUUID: res.projectUUID || prjct.id,
        }
      }
      return res
    })
    const project = newSessionList.find(
      (res) => res.projectId === prjct.titleCanonical
    )
    return { project, newSessionList }
  }

  const projectSwitch = async (prjct: ProjectListDetails) => {
    const { project, newSessionList } = getProject(prjct)
    if (project) {
      await triggerFirebaseProjectSwitch({
        projectId: project.projectUUID || prjct.id,
        projectName: project.projectId || prjct.titleCanonical,
        projectLabel: project.projectName || prjct.title,
      })
      setTimeout(async () => {
        await firebaseControlQuery.update({
          [`projectSwitch`]: {
            projectId: '',
            projectLabel: '',
            projectName: '',
          },
        })
        dispatch(
          setProjectIdentity({
            ...projectIdentity,
            sessionList: newSessionList,
            projectId: project.projectId,
            projectName: project.projectName || prjct.title,
            projectUUID: project.projectUUID || prjct.id,
            sessionId: project.sessionId || prjct.titleCanonical,
          })
        )
        dispatch(removeShortlist())
        history.replace(`/${activeRouteFirebase}`)
        // window.location.reload()
      }, 500)
      modalToggle(false)
      return
    }
    alert('There are no sessions for the selected project.')
  }

  React.useEffect(() => {
    setProjectQuery(query)
  }, [query])

  React.useEffect(() => {
    projectCanonicalName('')
    setProjectQuery('')
  }, [isModalActive])

  return (
    <>
      <Modal
        toggleModal={(res) => modalToggle(res)}
        isVisible={isModalActive}
        modalWidth="max-w-lg"
        title="Project Switch"
        noMainControls
        hasHeaderControl
        theme={{
          textColor: 'text-mainColour',
          mainBg: 'bg-mainColour',
          secondaryBg: 'bg-neutralColour',
        }}
      >
        <div>
          <div
            className={`my-4 h-auto w-full transition-opacity ${
              isActive ? 'bg-secondaryColour' : 'bg-gray-200'
            }`}
          >
            {projectIdentity.searchQuery && (
              <div>
                <input
                  type="text"
                  name="sessionName"
                  value={canonicalName}
                  onChange={(e) => projectCanonicalName(e.target.value)}
                  className="border-1 my-2 w-full rounded border-gray-500 text-2xl focus:border-gray-500 focus:ring-0"
                />
              </div>
            )}

            {projectList.map((project) => (
              <button
                key={`${project.id}-project-switch`}
                disabled={!isActive}
                onClick={() => projectSwitch(project)}
                type="button"
                className="flex h-auto w-full items-center border-t-2 px-2 py-4 text-2xl text-mainColour"
              >
                {project.title}
                <ArrowRefreshSvg
                  className={`ml-auto ${
                    !isActive ? 'text-gray-500' : 'text-sky-500'
                  }`}
                />
              </button>
            ))}
          </div>
          {pagination.total > constants.PAGE_SIZE && (
            <div className="flex items-center justify-between">
              <div>
                Page <b>{currentPage}</b> of <b>{pageCount}</b>
              </div>

              {!isActive && (
                <p className="inline-flex items-center gap-2 text-gray-500">
                  <ArrowRefreshSvg className="animate-spin text-sky-500" />{' '}
                  Loading...
                </p>
              )}

              <div
                className={`flex items-center gap-4 ${
                  !isActive ? 'text-gray-500' : ''
                }`}
              >
                <button
                  disabled={!isActive}
                  type="button"
                  onClick={() => setPage('prev')}
                  className="font-medium"
                >
                  Prev
                </button>
                |
                <button
                  disabled={!isActive}
                  type="button"
                  onClick={() => setPage('next')}
                  className="font-medium"
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <button
        disabled={!isActive}
        type="button"
        onClick={() => modalToggle(true)}
        className={`ml-auto flex w-auto cursor-pointer items-center gap-1 rounded-lg text-mainColour ${
          isActive ? 'bg-neutralColour' : 'bg-gray-200'
        } px-4 py-2.5 text-2xl`}
      >
        {projectIdentity.projectName}
        <ChevronUpDownSvg width="35" height="35" />
      </button>
    </>
  )
}

export default connect(({ firestore }: RootStateFirebase) => ({
  activeRouteFirebase: getSession(firestore)?.activeRoute,
}))(ProjectSwitchHandler)
