import React from 'react'

import BlockCard from '@components/block-card'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'

interface ComponentPropsInterface {
  projectId: string
  handleBlockClick: (block: string) => void
}

const BlockList = ({
  projectId,
  handleBlockClick,
}: ComponentPropsInterface) => {
  const [isLoading, setLoadingState] = React.useState(true)
  const [blocks, setBlocks] = React.useState<Array<string>>([])

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectId },
    { selectFromResult }
  )

  React.useEffect(() => {
    const { isError, isLoaded, levels } = buildingPayload
    if (blocks.length === 0 && !isError && levels.length > 0) {
      let blockList: Array<string> = []

      if (buildingPayload.blockOrders.length > 1) {
        blockList = buildingPayload.blockOrders
      } else {
        levels.forEach((level: Level) => {
          level.data.forEach((unit: Unit) => {
            const { blockId } = unit
            if (!blockList.includes(blockId)) blockList.push(blockId)
          })
        })
      }
      setBlocks(blockList)
    }
    setLoadingState(!isLoaded)
  }, [buildingPayload])

  if (isLoading)
    return (
      <div className="fixed inset-0 z-10 bg-secondaryColour bg-opacity-50">
        <div className="absolute left-50% top-[45%] h-24 w-24 animate-spin rounded-full border-4 border-solid border-neutralColour border-t-transparent text-center"></div>
      </div>
    )

  return (
    <>
      {blocks.length > 0 ? (
        blocks.map((block: string) => (
          <BlockCard
            key={block}
            label={block}
            handleClick={() => handleBlockClick(block)}
          />
        ))
      ) : (
        <div className="cursor-hand w-full rounded-lg bg-gradient py-10 pl-4 text-heading font-medium text-neutralColour shadow-standard">
          No data
        </div>
      )}
    </>
  )
}

export default BlockList
