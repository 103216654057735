import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryInterface, GalleryItemInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface ImagesInterface {
  id: string
  label: string
  distance: string
  imageSource: string
  imageName: string
}

export interface LocationDataInterface {
  label: string
  Images: Array<ImagesInterface>
}

type LocationTypeInterface = {
  projectName: string
}

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getLocation: builder.query<
      ApiResponse<Array<LocationDataInterface>>,
      LocationTypeInterface
    >({
      query: (params: LocationTypeInterface) => ({
        url: `/v1/${params.projectName}/location${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processGalleryData = (
  data: Array<LocationDataInterface>
): Array<GalleryInterface> => {
  const galleries: Array<GalleryInterface> = []

  data.forEach((locationData: LocationDataInterface) => {
    const items: Array<GalleryItemInterface> = []

    locationData.Images.forEach((image: ImagesInterface) => {
      items.push({
        id: image.id,
        title: image.imageName ?? 'Untitled',
        imageSource: image.imageSource,
      })
    })

    galleries.push({ label: locationData.label, items })
  })
  return galleries
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  locationData: GalleryInterface[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  locationData: processGalleryData(data?.data || []),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetLocationQuery } = locationApi
