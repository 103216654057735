import React from 'react'

const StageOrBuildingSkeleton = () => (
  <div className="h-full w-full p-5">
    <div className="cursor-hand mb-[15px] h-16 w-40 rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
      <div className="shimmer-container relative h-full opacity-50"></div>
    </div>

    <div className="mt-[15px] flex gap-4">
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    </div>
    <div className="mt-[15px] flex gap-4">
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    </div>
    <div className="mt-[15px] flex gap-4">
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    </div>
    <div className="mt-[15px] flex gap-4">
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="cursor-hand h-32 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    </div>
  </div>
)

export default StageOrBuildingSkeleton
