import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { TokenInterface } from '@store/actionSlices/token'
import { RootStateFirebase } from '@store/types'

import { hasToken } from '@utilities/token-helper'

export type PublicRouteProps = {
  authenticatedPath: string
  token: TokenInterface
} & RouteProps

function PublicRoutes({
  authenticatedPath,
  token,
  ...routeProps
}: PublicRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)

  React.useEffect(() => {
    if (hasToken(token)) {
      setIsAuthenticated(true)
    }
  }, [token])

  if (!isAuthenticated) {
    return <Route {...routeProps} />
  }
  return <Redirect to={{ pathname: authenticatedPath }} />
}

export default connect(({ token }: RootStateFirebase) => ({
  token,
}))(PublicRoutes)
