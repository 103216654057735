import React from 'react'

import { SvgProps } from './types'

const LogoutSvg = ({ width, height, className }: SvgProps) => (
  <svg
    className={className}
    width={width || '52'}
    height={height || '52'}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 52"
    stroke="currentColor"
  >
    <path
      d="M47.6667 39V13C47.6667 11.2761 46.9818 9.62279 45.7629 8.40381C44.5439 7.18482 42.8906 6.5 41.1667 6.5H36.8333C35.1094 6.5 33.4561 7.18482 32.2371 8.40381C31.0182 9.62279 30.3333 11.2761 30.3333 13V39C30.3333 40.7239 31.0182 42.3772 32.2371 43.5962C33.4561 44.8152 35.1094 45.5 36.8333 45.5H41.1667C42.8906 45.5 44.5439 44.8152 45.7629 43.5962C46.9818 42.3772 47.6667 40.7239 47.6667 39Z"
      strokeWidth="3.25"
    />
    <path
      d="M17.3333 6.5H13C10.7015 6.5 8.49706 7.41309 6.87174 9.03841C5.24643 10.6637 4.33334 12.8681 4.33334 15.1667V36.8333C4.33334 39.1319 5.24643 41.3363 6.87174 42.9616C8.49706 44.5869 10.7015 45.5 13 45.5H17.3333M30.3333 26H13M13 26L19.5 19.5M13 26L19.5 32.5"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LogoutSvg
