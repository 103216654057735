import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { Level } from '@api/building'
import { MappingBlockCollection } from '@api/interactive-plan'

export interface BlockLevelInterface {
  levels: Array<Level>
  blocks: MappingBlockCollection
}

const initialState = {
  levels: [],
  blocks: {},
} as BlockLevelInterface

const blockLevelSlice = createSlice({
  name: 'blockLevel',
  initialState,
  reducers: {
    setBlockLevelData: (
      state: BlockLevelInterface,
      action: PayloadAction<Partial<BlockLevelInterface>>
    ) => ({
      ...state,
      ...action.payload,
    }),
    resetBlockLevelData: () => ({
      levels: [],
      blocks: {},
    }),
  },
})

export const { setBlockLevelData, resetBlockLevelData } =
  blockLevelSlice.actions

export const selectBlockLevel = (state: RootStateTypeExtra) => state.blockLevel

export default blockLevelSlice.reducer
