const FILTER_INITIAL_STATE = {
  availability: 'available',
  range: {
    min: 'Any',
    max: 'Any',
  },
  configurations: {
    bed: {
      min: 'Any',
      max: 'Any',
    },
    study: {
      min: 'Any',
      max: 'Any',
    },
    bath: {
      min: 'Any',
      max: 'Any',
    },
    powderRoom: {
      min: 'Any',
      max: 'Any',
    },
    car: {
      min: 'Any',
      max: 'Any',
    },
  },
  aspect: [],
  unitType: 'Any',
  showAvailable: false,
  anyLevel: true,
  apply: false,
}

export default FILTER_INITIAL_STATE
