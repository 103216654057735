import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  GalleryControlInterface,
  GalleryInterface,
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import {
  CarouselItem,
  CarouselOverlay,
  WithCarouselControl,
} from '@components/carousel-handler/elements'

import FirebaseControlQuery from '@utilities/firebase-control-query'

interface CarouselSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}

interface CarouselHandlerProps {
  carouselName: string
  carousel: GalleryInterface
  carouselSession: CarouselSessionInterface | undefined
  projectIdentity: ProjectIdentity
  roundedControl?: boolean
  hideControls?: boolean
  onEnlargedView?: (viewState: boolean) => void
  enableEnlargedView?: boolean
  hasBlurredEffect?: boolean
  imgClass?: string
  disableOverlay?: boolean
  showEnlargedView?: boolean
}

const CarouselHandler = ({
  carouselName,
  carousel,
  carouselSession,
  projectIdentity,
  roundedControl,
  hideControls,
  onEnlargedView,
  enableEnlargedView = false,
  imgClass,
  hasBlurredEffect,
  disableOverlay,
  showEnlargedView = false,
}: CarouselHandlerProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const [activeCarouselItemIndex, setActiveCarouselItemIndex] =
    React.useState(0)
  const activeItem = React.useMemo(
    () => carousel.items[activeCarouselItemIndex],
    [carousel, activeCarouselItemIndex]
  )

  const handleItemClick = async (id: string, type: string) => {
    await firebaseControlQuery.update({
      [`${carouselName}.galleryControl.isPlaying`]: false,
      [`${carouselName}.galleryControl.activeItemID`]: id,
      [`${carouselName}.playerControl.playerState`]: 'stop',
      [`${carouselName}.playerControl.isPlayerVisible`]: false,
    })
    if (type === 'video') {
      await firebaseControlQuery.update({
        [`${carouselName}.playerControl.isPlayerVisible`]: true,
        [`${carouselName}.playerControl.playerState`]: 'play',
      })
    }
    if (type === 'image') {
      await firebaseControlQuery.update({
        [`${carouselName}.playerControl.currentVideoPositionInSeconds`]: -1,
        [`${carouselName}.playerControl.scrubPositionInSeconds`]: -1,
      })
    }
  }

  const handleEnlargedView = (viewState: boolean) => {
    if (enableEnlargedView) {
      onEnlargedView?.(viewState)
    }
  }

  const handleActiveItemIndexChange = (index: number, type = 'image') => {
    const selectedItem = carousel?.items?.[index]
    handleItemClick(selectedItem.id, type)
  }

  React.useEffect(() => {
    if (carouselSession) {
      const {
        galleryControl: { activeItemID: activeItemIDFirebase },
      } = carouselSession

      const activeItemIndexFirebase = carousel?.items?.findIndex(
        (item) => item?.id === activeItemIDFirebase
      )
      setActiveCarouselItemIndex(
        activeItemIndexFirebase !== -1 ? activeItemIndexFirebase : 0
      )
    }
  }, [carouselSession, carousel])

  return (
    <div className="relative h-full w-full">
      <WithCarouselControl
        activeItemIndex={activeCarouselItemIndex}
        onChangeActiveItemIndex={handleActiveItemIndexChange}
        itemsCount={carousel.items.length}
        rounded={roundedControl}
        hideControls={hideControls}
      >
        <TransitionGroup component={null}>
          <CSSTransition
            key={`${activeCarouselItemIndex}-${activeItem?.id}`}
            classNames="transition-fade"
            timeout={300}
          >
            <div
              className="absolute inset-0 h-full w-full"
              key={`${activeCarouselItemIndex}-${activeItem?.id}`}
            >
              <CarouselItem
                carouselData={{
                  index: activeCarouselItemIndex,
                  item: activeItem,
                }}
                onItemClick={(index, type) => {
                  if (type === 'video') {
                    handleItemClick(activeItem.id, type)
                  } else {
                    handleEnlargedView(true)
                  }
                }}
                cursorClassName={
                  enableEnlargedView ? 'cursor-pointer' : 'cursor-default'
                }
                imgClass={imgClass}
                hasBlurredEffect={hasBlurredEffect}
                videoPlayerState={carouselSession?.playerControl?.playerState}
              />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </WithCarouselControl>

      {!disableOverlay && (
        <CarouselOverlay
          carouselData={{
            index: activeCarouselItemIndex,
            item: activeItem,
          }}
          carouselItems={carousel.items}
          onItemClick={(index, type) =>
            handleActiveItemIndexChange(index, type)
          }
          showEnlargedView={showEnlargedView}
          onOverlayClick={() => handleEnlargedView(false)}
          playerSession={carouselSession?.playerControl}
        />
      )}
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(CarouselHandler)
