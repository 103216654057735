import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, ProjectType, RootStateFirebase } from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'

interface EmptyStateProps {
  projectIdentity: ProjectIdentity
}

const EmptyState = ({ projectIdentity }: EmptyStateProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const {
    isSnaploaderActive: hasSnaploader,
    isEnvisionVRActive: hasEnvisionVR,
    type,
  } = projectIdentity

  const handleNavigateToResidences = () => {
    if (type === ProjectType.HouseAndLand) {
      firebaseControlQuery.updateRoute('area-view-house-and-land')
      return
    }

    if (hasSnaploader) {
      firebaseControlQuery.updateRoute('snaploader-view')
      return
    }

    if (hasEnvisionVR) {
      firebaseControlQuery.updateRoute('3d-building')
      return
    }

    firebaseControlQuery.updateRoute('area-view')
  }

  return (
    <div className="flex w-full flex-col items-center justify-center rounded-lg bg-neutralColour p-6">
      <p className="mb-6 text-heading font-medium">Your Shortlist is empty</p>
      <p className="mb-11 text-default">Add your favourite properties in it.</p>
      <button
        type="button"
        className="rounded-xl bg-gradient px-12 py-4 text-subHeading font-medium text-white shadow-standard"
        onClick={handleNavigateToResidences}
      >
        {type === ProjectType.HouseAndLand
          ? 'Explore Precinct'
          : 'Explore Residences'}
      </button>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(EmptyState)
