import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface GalleryData {
  id: string
  imageSource: string
  imageName: string
  label: string
}

type GalleryType = {
  projectName: string
}

export const galleryApi = createApi({
  reducerPath: 'galleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getGallery: builder.query<ApiResponse<Array<GalleryData>>, GalleryType>({
      query: (params: GalleryType) => ({
        url: `/v1/${params.projectName}/design${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processGalleryData = (
  data: Array<Array<GalleryData>>
): Array<GalleryInterface> => {
  const galleries: Array<GalleryInterface> = []
  Object.keys(data).forEach((value: any) => {
    galleries.push({
      label: value.charAt(0).toUpperCase() + value.slice(1),
      items: data[value].map((item: GalleryData) => ({
        id: item.id,
        title: item.label || item.imageName,
        imageSource: item.imageSource,
      })),
    })
  })

  return galleries
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  galleries: GalleryInterface[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  galleries: processGalleryData(data?.data || []).filter(
    (res: GalleryInterface) => res.items.length > 0
  ),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetGalleryQuery } = galleryApi
