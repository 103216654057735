import { Location } from 'history'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import GallerySkeleton from '@components/gallery-skeleton'
import SlideshowControl from '@components/slideshow-control'

import {
  selectFromResult as selectFromGalleryResult,
  useGetGalleryQuery,
} from '@api/gallery'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { isNotSessionSettingsOrTools } from '@utilities/helper'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const Gallery = ({ session, projectIdentity }: PagePropsInterface) => {
  const history = useHistory()
  const [shouldShowStartSlideshowBtn, setSetShouldStartSlideshowBtn] =
    React.useState<boolean>(false)

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  React.useEffect(() => {
    const handleRouteChange = async (location: Location) => {
      const { pathname } = location
      if (isNotSessionSettingsOrTools(pathname)) {
        await firebaseControlQuery.update({
          [`design.galleryControl.isPlaying`]: false,
        })
      }
    }

    const unsubscribeHistory = history.listen(handleRouteChange)

    return () => {
      unsubscribeHistory()
    }
  }, [history])

  const galleryPayload = useGetGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromGalleryResult }
  )

  React.useEffect(() => {
    if (!session || !session.design) {
      return
    }

    const { galleries, isLoaded, isError } = galleryPayload

    if (!isLoaded || isError || !galleries.length) {
      return
    }

    const {
      design: {
        galleryControl: { activeTabIndex },
      },
    } = session

    const data = galleries[activeTabIndex]

    setSetShouldStartSlideshowBtn(data?.items.length > 1)
  }, [session?.design?.galleryControl.activeTabIndex, galleryPayload.galleries])

  return (
    <Container>
      <DataHandler
        payload={{ ...galleryPayload, data: galleryPayload.galleries }}
        skeletonFrame={<GallerySkeleton hasTabBar />}
      >
        <div className="h-full w-full">
          <div className="relative flex h-top-bar w-full items-center justify-center px-5 text-neutralColour">
            <div className="text-title font-medium">Gallery</div>
            {shouldShowStartSlideshowBtn && (
              <div className="absolute bottom-14 right-5">
                <SlideshowControl galleryName="design" />
              </div>
            )}
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <GalleryHandler
              galleryName="design"
              galleries={galleryPayload.galleries}
              gallerySession={session?.design}
              slideView
              previousRoute={String(session?.previousRoute)}
            />
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Gallery)
