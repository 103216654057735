import React from 'react'

export interface CheckboxInputInterface {
  initialState: boolean
  onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  id: string
  onColor?: string
  offColor?: string
}
const CheckboxInput = ({
  id,
  initialState,
  onChangeCallback,
  isDisabled = false,
  onColor = 'bg-mainColour',
  offColor = 'bg-white',
}: CheckboxInputInterface) => {
  const [activeColor, setActiveColor] = React.useState(offColor)
  React.useEffect(() => {
    setActiveColor(initialState ? onColor : offColor)
  }, [initialState])
  return (
    <label
      className={`relative h-4 w-4 cursor-pointer rounded  ${offColor}`}
      htmlFor={id}
      aria-label={`checkbox-control ${id}`}
    >
      <input
        disabled={isDisabled}
        type="checkbox"
        checked={initialState}
        className="sr-only"
        onChange={onChangeCallback}
        id={id}
      />
      <span
        className={`absolute left-[3px] top-[3px] h-2.5 w-2.5 rounded-[2px]  transition-all duration-300 ${activeColor}`}
      ></span>
    </label>
  )
}
export default CheckboxInput
