import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryInterface, GalleryItemInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface ImagesInterface {
  id: string
  imageSource: string
  imageName: string
}

export interface BrochureDataInterface {
  label: string
  Images: Array<ImagesInterface>
}

type BrochureTypeInterface = {
  projectName: string
}

export const brochureApi = createApi({
  reducerPath: 'brochureApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getBrochure: builder.query<
      ApiResponse<Array<BrochureDataInterface>>,
      BrochureTypeInterface
    >({
      query: (params: BrochureTypeInterface) => ({
        url: `/v1/${params.projectName}/brochure${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processGalleryData = (
  data: Array<BrochureDataInterface>
): Array<GalleryInterface> => {
  const galleries: Array<GalleryInterface> = []
  data.forEach((brochureData: BrochureDataInterface) => {
    const items: Array<GalleryItemInterface> = []
    brochureData.Images.forEach((image: ImagesInterface) => {
      items.push({
        id: image.id,
        title: image.imageName ?? 'Untitled',
        imageSource: image.imageSource,
      })
    })
    galleries.push({ label: brochureData.label, items })
  })
  return galleries
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  brochureData: GalleryInterface[]
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  brochureData: processGalleryData(data?.data || []),
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetBrochureQuery } = brochureApi
