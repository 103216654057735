import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'

import { setOnlineStatus } from '@store/actionSlices/statusIndicator'
import { RootStateTypeExtra } from '@store/types'

import prepareHeaders from '@api/headers'

import {
  fetchResponseFromLocalStorage,
  isExistsInTheOfflineEndpointList,
  isOffline,
  storeResponseToLocalStorage,
} from '@utilities/offline-handler-util'
import { isTokenExpired, renewUserAccessToken } from '@utilities/token-helper'

const mutex = new Mutex()

const baseUrl = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders })

const getOfflineResponse = (args: FetchArgs) => {
  if (!isOffline()) {
    return null
  }

  if (!isExistsInTheOfflineEndpointList(args)) {
    return null
  }

  const response = fetchResponseFromLocalStorage(args)

  if (!response) {
    return null
  }

  return response
}

const baseQueryInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let fetchedFromAPI = false
  let isOnline = true

  const { token } = api.getState() as RootStateTypeExtra

  await mutex.waitForUnlock()

  if (isTokenExpired(token) && !mutex.isLocked()) {
    const release = await mutex.acquire()
    try {
      await renewUserAccessToken(token, api, extraOptions)
    } finally {
      release()
    }
  } else {
    await mutex.waitForUnlock()
  }

  const fetchArgsObject = typeof args === 'string' ? { url: args } : args

  let response: any = getOfflineResponse(fetchArgsObject)

  if (!response) {
    response = await baseQuery(args, api, extraOptions)

    if (response) {
      fetchedFromAPI = true

      if (response.error) {
        const { status } = response.error

        if (status === 401) {
          if (!mutex.isLocked()) {
            const release = await mutex.acquire()

            try {
              const renewStatus = await renewUserAccessToken(
                token,
                api,
                extraOptions
              )
              if (renewStatus) {
                response = await baseQuery(args, api, extraOptions)
              }
            } finally {
              release()
            }
          } else {
            await mutex.waitForUnlock()
            response = await baseQuery(args, api, extraOptions)
          }
        }

        if (status === 'FETCH_ERROR') {
          isOnline = false
        }
      }
    } else {
      isOnline = false
    }
  }

  if (
    isOffline() &&
    fetchedFromAPI &&
    !response.error &&
    isExistsInTheOfflineEndpointList(fetchArgsObject)
  ) {
    storeResponseToLocalStorage(fetchArgsObject, response)
  }

  api.dispatch(setOnlineStatus(isOnline))

  return response
}

export default baseQueryInterceptor
