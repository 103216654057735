import React from 'react'

import { DropDownOption, MinMaxInterface, PriceRange } from '@store/types'

import DropDown from '@components/dropdown'

export interface RangeOptionProps {
  field: string
  options: Array<string | number> | PriceRange
  range: MinMaxInterface
  handleChange: (range: MinMaxInterface) => void
}
const RangeOptions = ({
  field,
  options,
  range: { min, max },
  handleChange,
}: RangeOptionProps) => {
  const LABEL = {
    bed: 'Bedrooms',
    bath: 'Bathrooms',
    study: 'Study',
    car: 'Car spaces',
    powderRoom: 'Powder room',
    price: 'PRICE',
    lotFrontage: 'Lot frontage',
    lotArea: 'Lot area',
  }
  const prepearDropdownItems = (
    data: Array<string | number>,
    price?: boolean
  ): Array<DropDownOption> => {
    const dropDownOptions: Array<DropDownOption> = [
      { label: 'Any', value: 'Any' },
    ]
    data?.forEach((value: string | number) =>
      dropDownOptions.push({
        label: price
          ? `$${Number(value).toLocaleString('en-US')}`
          : value.toString(),
        value: value.toString(),
      })
    )
    return dropDownOptions
  }
  const disabledMaxOption = React.useCallback(
    (value: string) => {
      if (value === 'Any' || min === 'Any' || value >= min) return false
      return true
    },
    [min]
  )
  const disabledMinOption = React.useCallback(
    (value: string) => {
      if (value === 'Any' || max === 'Any' || value <= max) return false
      return true
    },
    [max]
  )

  return (
    <div className="flex flex-col gap-2.5" key={field}>
      <span
        className={`font-medium ${
          field === 'price' ? 'text-default' : 'text-xl'
        }`}
      >
        {LABEL[field as keyof typeof LABEL]}
      </span>
      <div className="flex rounded-lg bg-neutralColour">
        <DropDown
          onSelect={(value) => {
            handleChange({
              min: value,
              max,
            })
          }}
          items={prepearDropdownItems(
            Array.isArray(options) ? options : options.min,
            field === 'price'
          )}
          value={min}
          label="Min"
          disabledOption={disabledMinOption}
        />
        <DropDown
          onSelect={(value) => {
            handleChange({
              min,
              max: value,
            })
          }}
          items={prepearDropdownItems(
            Array.isArray(options) ? options : options.max,
            field === 'price'
          )}
          value={max}
          label="Max"
          disabledOption={disabledMaxOption}
        />
      </div>
    </div>
  )
}

export default RangeOptions
