import React from 'react'

import { ProjectIdentity, SessionMap } from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId } from '@utilities/firebase-util'

type ClearCacheProps = {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const useClearCache = ({
  projectIdentity,
  session,
}: ClearCacheProps): [boolean, () => void] => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [refreshTriggerKeyRestTimerId, setRefreshTriggerKeyRestTimerId] =
    React.useState<ReturnType<typeof setTimeout>>()
  const [isRefreshing, setRefreshState] = React.useState(false)

  const setRefreshTriggerKeyOnFirebase = (state: string, key = ''): void => {
    firebaseControlQuery.update({
      [`clearCacheTriggerKey`]: key,
      [`clearCacheKeyState`]: state,
    })
  }

  const handleRefreshClick = () => {
    setRefreshState(true)
    setRefreshTriggerKeyOnFirebase('registered', generateId(10))
  }

  const resetRefreshTriggerKeyTimer = () => {
    setRefreshState(false)
    if (refreshTriggerKeyRestTimerId) {
      clearTimeout(refreshTriggerKeyRestTimerId)
      setRefreshTriggerKeyRestTimerId(undefined)
    }
  }

  const resetRefreshKeyStateAndReload = async (state?: string) => {
    if (state !== 'cleared') return
    await firebaseControlQuery.update({
      [`clearCacheKeyState`]: '',
    })
    window.location.reload()
  }

  const handleRefreshTriggerKeyChange = async (
    refreshTriggerKey?: string,
    myRefreshKeyState?: string
  ) => {
    if (!refreshTriggerKey) {
      resetRefreshTriggerKeyTimer()
      resetRefreshKeyStateAndReload(myRefreshKeyState)
      return
    }

    if (!refreshTriggerKeyRestTimerId) {
      setRefreshTriggerKeyRestTimerId(
        setTimeout(() => {
          setRefreshTriggerKeyOnFirebase('cleared', '')
        }, 30 * 1000)
      )
    }
  }

  React.useEffect(() => {
    if (session) {
      const {
        clearCacheTriggerKey: firebaseRefreshTriggerKey,
        clearCacheKeyState: firebaseRefreshKeyState,
      } = session
      handleRefreshTriggerKeyChange(
        firebaseRefreshTriggerKey,
        firebaseRefreshKeyState
      )
    }
  }, [session])

  return [isRefreshing, handleRefreshClick]
}

export default useClearCache
