import React from 'react'

import { SvgProps } from './types'

const FunnelSvg = ({
  width,
  height,
  className,
  stroke,
  strokeColor,
}: SvgProps) => (
  <svg
    className={`${className}`}
    width={width || '32'}
    height={height || '33'}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke={strokeColor || 'currentColor'}
    viewBox="0 0 32 33"
  >
    <path
      d="M4 9.83333V5.83333C4 5.47971 4.14048 5.14057 4.39052 4.89052C4.64057 4.64048 4.97971 4.5 5.33333 4.5H26.6667C27.0203 4.5 27.3594 4.64048 27.6095 4.89052C27.8595 5.14057 28 5.47971 28 5.83333V9.83333M4 9.83333L12.8667 17.4333C13.013 17.5585 13.1305 17.7139 13.2111 17.8888C13.2916 18.0638 13.3333 18.2541 13.3333 18.4467V26.7933C13.3334 26.9959 13.3796 27.1957 13.4684 27.3777C13.5572 27.5597 13.6864 27.7191 13.846 27.8438C14.0056 27.9685 14.1915 28.0551 14.3896 28.0972C14.5877 28.1393 14.7928 28.1357 14.9893 28.0867L17.656 27.42C17.9446 27.348 18.2008 27.1816 18.3839 26.9473C18.5671 26.7129 18.6666 26.4241 18.6667 26.1267V18.4467C18.6667 18.2541 18.7084 18.0638 18.7889 17.8888C18.8695 17.7139 18.987 17.5585 19.1333 17.4333L28 9.83333M4 9.83333H28"
      strokeWidth={stroke || 2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default FunnelSvg
