import React from 'react'
import { useDispatch } from 'react-redux'

import {
  setPackageSummary,
  setPrecinctList,
} from '@store/actionSlices/houseAndLand'

import {
  selectPackageSummaryFromResult as selectFromResult,
  selectPrecinctListFromResult,
  useGetPackageSummaryQuery,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'

interface ComponentProps {
  projectName: string
  precinctId: string
}
const useGetFilterData = ({ projectName, precinctId }: ComponentProps) => {
  const dispatch = useDispatch()
  const { packageSummary, isFetching, status, isError, isLoaded } =
    useGetPackageSummaryQuery(
      {
        projectName,
        precinctId,
        lots: true,
      },
      { selectFromResult, skip: precinctId === '' }
    )
  const precinctsPayload = useGetPrecinctListQuery(
    { projectName },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )
  React.useEffect(() => {
    if (Object.keys(packageSummary).length > 0) {
      dispatch(
        setPackageSummary({
          [precinctId]: packageSummary,
        })
      )
    }
  }, [packageSummary])
  React.useEffect(() => {
    const { precinctList } = precinctsPayload
    if (precinctList?.length > 0) {
      dispatch(setPrecinctList(precinctList))
    }
  }, [precinctsPayload])
  return { isFetching, status, isError, isLoaded }
}
export default useGetFilterData
