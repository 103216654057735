import React from 'react'

import { DropDownOption } from '@store/types'

import { ChevronSvg } from '@svg/react'

export interface DropDownProps {
  items: Array<DropDownOption>
  value: string
  onSelect: (arg: string) => void
  label?: string
  disabledOption?: (item: string) => boolean
  className?: string
  optionClassName?: string
  disabled?: boolean
  placeholder?: string
  useValueAsKey?: boolean
}
const DropDown = ({
  items,
  value,
  onSelect,
  label,
  disabled,
  disabledOption,
  className = '',
  optionClassName = '',
  placeholder,
  useValueAsKey,
}: DropDownProps) => {
  const [isOpen, toggleOpen] = React.useState(false)
  const dropDownRef = React.useRef<HTMLDivElement>(null)

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node) &&
      isOpen
    ) {
      toggleOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  const displayedValue = React.useMemo(() => {
    const selectedValue = items.find((item) => item.value === value)
    return selectedValue?.label || value
  }, [items, value])

  return (
    <div className="relative w-full" ref={dropDownRef}>
      <button
        type="button"
        className={`w-full border ${
          isOpen
            ? 'rounded-tl-lg rounded-tr-lg border-mainColour'
            : 'rounded-lg border-transparent'
        }  bg-neutralColour text-mainColour transition duration-200 ease-in-out ${
          disabled ? 'pointer-events-none' : ''
        } px-4 py-2 ${className}`}
        onClick={() => toggleOpen(!isOpen)}
      >
        {label && <span className="flex text-base">{label}</span>}
        <div className="flex items-center justify-between  text-xl">
          <span
            className={`${disabled ? 'text-tertiaryColour opacity-30' : ''}`}
          >
            {displayedValue || placeholder}
          </span>
          <ChevronSvg
            className={`${
              disabled ? 'text-tertiaryColour' : ''
            } h-6 w-6 transition duration-200 ease-in-out`}
            rotate={isOpen ? 'top' : 'down'}
          />
        </div>
      </button>

      {isOpen && (
        <button
          className={`absolute bottom-0 left-0 z-30 w-full rounded rounded-bl-lg rounded-br-lg border-b border-mainColour ${
            label ? ' py-9' : 'py-6'
          }`}
          type="button"
          onClick={(e) => {
            e.stopPropagation()
            toggleOpen(!isOpen)
          }}
        ></button>
      )}

      <div
        className={`absolute ${
          label ? 'top-[69px]' : 'top-11'
        } z-20 w-full overflow-y-auto rounded-bl-lg rounded-br-lg border-b border-l border-r border-mainColour bg-neutralColour px-2 pt-3 transition duration-200 ease-in-out ${
          isOpen ? 'visible max-h-56' : 'invisible max-h-0'
        } ${optionClassName}`}
      >
        {items.map((item: DropDownOption) => (
          <div
            key={
              useValueAsKey || typeof item.label !== 'string'
                ? item.value
                : item.label
            }
          >
            <button
              type="button"
              onClick={() => {
                if (item.value !== value) {
                  toggleOpen(false)
                  onSelect(item.value)
                }
              }}
              className={`hover:bg-mainColour/30 flex w-full w-full cursor-pointer items-center  justify-between rounded p-2 text-left text-xl last:mb-2 ${
                value === item.value ? 'bg-mainColour/30' : ''
              }  ${
                disabledOption &&
                disabledOption(item.value) &&
                'pointer-events-none text-[#CCCCCC]'
              }`}
            >
              {item.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
export default DropDown
