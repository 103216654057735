interface ArgsInterface {
  url: string
}

const offlineEndpoints = [
  'brochure',
  'building',
  'external-views',
  'floor-plates',
  'design',
  'interactive-plan',
  'interactive-map',
  'location',
  'panoramic',
  'shortlist',
  'teams',
  'unit-galleries',
  'videos',
  'vision',
]

const prefix = 'offline-api-response:'

const getLocalStorageKey = (args: ArgsInterface): string => {
  const { url } = args
  return `${prefix}${url}`
}

const fetchResponseFromLocalStorage = (args: ArgsInterface): any => {
  const localStorageKey = getLocalStorageKey(args)
  const data = localStorage.getItem(localStorageKey)

  return data ? JSON.parse(data) : null
}

const isExistsInTheOfflineEndpointList = (args: ArgsInterface): boolean => {
  const { url } = args
  const endpoint = url.split('/')[3] || ''

  if (!endpoint || !offlineEndpoints.includes(endpoint)) {
    return false
  }

  return true
}

const isOffline = (): boolean => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return false
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.offlineMode || false
}

const removeOfflineResponseFromLocalStorage = (): void => {
  Object.keys(localStorage).forEach((key: string) => {
    if (key.includes(prefix)) {
      localStorage.removeItem(key)
    }
  })
}

const storeResponseToLocalStorage = (
  args: ArgsInterface,
  response: any
): void => {
  const localStorageKey = getLocalStorageKey(args)
  localStorage.setItem(localStorageKey, JSON.stringify(response))
}

export {
  fetchResponseFromLocalStorage,
  isExistsInTheOfflineEndpointList,
  isOffline,
  removeOfflineResponseFromLocalStorage,
  storeResponseToLocalStorage,
}
