import React from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  MenuItemInterface,
  ProjectIdentity,
  ProjectType,
  RootStateFirebase,
} from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { capitalizeFirstLetter } from '@utilities/helper'

import BottomNavigation from './bottom-navigation'
import LeftNavigation from './left-navigation'
import MENU_ITEMS from './menu-items'

interface ComponentPropsInterface {
  type: 'bottom-navigation' | 'left-navigation'
  projectIdentity: ProjectIdentity
  children?: React.ReactNode
  shortlist: ShortlistState
}

const Navigation = ({
  type,
  projectIdentity,
  children,
  shortlist,
}: ComponentPropsInterface) => {
  const location = useLocation()
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const handleMenuClick = async (item: MenuItemInterface) => {
    const { route, routeViaFirebase } = item
    history.push({ pathname: `/${route}`, state: { from: location.pathname } })
    if (routeViaFirebase) {
      await firebaseControlQuery.updateRoute(route)
    }
  }

  const updateResidencesRoute = (
    residencesMenu: MenuItemInterface,
    snaploaderState: boolean,
    envisionVRState: boolean,
    projectType: ProjectType
  ): MenuItemInterface => {
    if (snaploaderState) return { ...residencesMenu, route: 'snaploader-view' }
    if (envisionVRState) return { ...residencesMenu, route: '3d-building' }
    if (projectType === ProjectType.HouseAndLand)
      return { ...residencesMenu, route: 'area-view-house-and-land' }
    return residencesMenu
  }

  const navItems = React.useMemo(() => {
    const {
      navigationSettings,
      disconnect,
      showClearCacheOption,
      isSnaploaderActive,
      isEnvisionVRActive,
      type: projectType,
    } = projectIdentity

    return MENU_ITEMS.map((item: MenuItemInterface) => {
      const foundItem = navigationSettings.find(
        (navigationSetting: MenuItemInterface) =>
          navigationSetting.key === item.key
      )

      const newItem = foundItem
        ? {
            ...item,
            label: capitalizeFirstLetter(foundItem.label),
            active: foundItem.active,
            order: foundItem.order,
          }
        : item

      if (newItem.key === 'tools') {
        return { ...newItem, visibility: disconnect || showClearCacheOption }
      }

      let myItem = { ...newItem }

      if (newItem.key === 'residences') {
        myItem = updateResidencesRoute(
          myItem,
          isSnaploaderActive,
          isEnvisionVRActive,
          projectType
        )
      }

      return myItem
    }).sort((a, b) => {
      if (!Number.isNaN(a.order ?? NaN) && !Number.isNaN(b.order ?? NaN)) {
        return Number(a.order) - Number(b.order)
      }
      return MENU_ITEMS.indexOf(a) - MENU_ITEMS.indexOf(b)
    })
  }, [projectIdentity])

  const activeRoute = React.useMemo(() => {
    const paths = location.pathname.split('/')
    return paths[1] || ''
  }, [location])

  return (
    <>
      {type === 'left-navigation' ? (
        <div className="absolute left-0 top-0 z-10 h-screen w-[30%] rounded-br-lg rounded-tr-lg bg-neutralColour shadow-nav">
          <LeftNavigation
            menuItems={navItems}
            handleClick={handleMenuClick}
            activeRoute={activeRoute}
            shortlistCount={
              location.pathname === '/shortlist'
                ? shortlist.shortlists.length
                : undefined
            }
          />
        </div>
      ) : (
        <div className="absolute h-full w-full">
          <BottomNavigation menuItems={navItems} handleClick={handleMenuClick}>
            {children}
          </BottomNavigation>
        </div>
      )}
    </>
  )
}

export default connect(({ projectIdentity, shortlist }: RootStateFirebase) => ({
  shortlist,
  projectIdentity,
}))(Navigation)
