import React from 'react'

import { CloseSvg } from '@svg/react'

export interface TextInputInterface {
  label: string
  value: string
  name: string
  placeholder?: string
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClear?: () => void
  className?: string
  border?: string
  error?: string
}
const TextInput = ({
  name,
  label,
  value,
  placeholder,
  disabled,
  className = '',
  border = 'border-l border-r border-b border-t',
  onChange,
  onClear,
  error,
}: TextInputInterface) => (
  <>
    <div
      className={`flex flex-col rounded-lg p-2 text-default ${className} ${border}`}
    >
      {error && <small className="text-left text-red-500">{error}</small>}

      <div className="flex items-center text-fontBlackColour">
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          id={name}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
          onChange={onChange}
          className={`w-full border-none text-default focus:border-[#D9D9D9] focus:ring-0 ${
            disabled ? 'pointer-events-none cursor-not-allowed' : ''
          }`}
        />
        <button type="button" className="px-2" onClick={onClear}>
          <CloseSvg
            className="mr-2 h-2.5 w-2.5 text-fontBlackColour opacity-60"
            strokeColor="currentColor"
          />
        </button>
      </div>
    </div>
  </>
)

export default TextInput
