import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  InteractiveMapItemInterface,
  InteractiveMapListInterface,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryString } from '@utilities/helper'

export interface Layers {
  groupId: string
  label: string
  controlSrc: string
  src: string
  animation?: string
  visible: boolean
  controls: boolean
  type: string
  category: string
  baseImageOverride: string
  categoryDefault: boolean
  actionRoute: string
}

export interface InteractiveMapData {
  src: string
  layers: Array<Layers>
  storyMode: boolean
}

type InteractiveMapType = {
  projectName: string
}

export const interactiveMapApi = createApi({
  reducerPath: 'interactiveMapApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getInteractiveMap: builder.query<
      ApiResponse<Array<InteractiveMapData>>,
      InteractiveMapType
    >({
      query: (params: InteractiveMapType) => ({
        url: `/v1/${params.projectName}/interactive-map${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processData = (
  data: InteractiveMapData
): Array<InteractiveMapListInterface> => {
  const layers: Array<Layers> = data?.layers || []
  const categories: string[] = []

  layers.forEach((layer: Layers) => {
    if (!categories.includes(layer.category) && layer.controls) {
      categories.push(layer.category)
    }
  })

  return categories.map((category) => {
    const itemsByCat = layers.filter(
      (layer) => layer.category === category && layer.controls
    )
    const items = itemsByCat.map(
      (layer: Layers): InteractiveMapItemInterface => ({
        label: layer.label ?? 'Untitled',
        controlSrc: layer.controlSrc,
        groupId: layer.groupId,
        actionRoute: layer.actionRoute,
        visible: layer.visible,
        categoryDefault: layer.categoryDefault,
        baseImageOverride: layer.baseImageOverride,
      })
    )
    return { label: category, items }
  })
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  interactiveMapData: InteractiveMapListInterface[]
  storyMode: boolean
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  interactiveMapData: processData(data?.data || {}),
  storyMode: data?.data.storyMode,
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetInteractiveMapQuery } = interactiveMapApi
