import React from 'react'

import { MenuItemInterface } from '@store/types'

import Branding from '@components/branding/Branding'

interface ComponentPropsInterface {
  menuItems: Array<MenuItemInterface>
  activeRoute: string
  handleClick: (arg: MenuItemInterface) => void
  shortlistCount?: number
}

const LeftNavigation = ({
  menuItems,
  activeRoute,
  handleClick,
  shortlistCount,
}: ComponentPropsInterface) => (
  <div className="relative h-full w-full">
    <div className="pb-9">
      <Branding className="h-20 p-5" />
    </div>
    <div className="no-scrollbar h-left-navigation-container overflow-y-scroll">
      <ul>
        {menuItems
          .filter(
            (item: MenuItemInterface) =>
              item.active && item.type === 'main-menu'
          )
          .map((item: MenuItemInterface) => (
            <li
              key={item.key}
              className={`${
                activeRoute === item.route ||
                item?.relatedRoutes?.includes(activeRoute)
                  ? 'left-nav-active-menu-item'
                  : ''
              } left-nav-menu-item`}
            >
              <button type="button" onClick={() => handleClick(item)}>
                {item.label}{' '}
                {item.key === 'shortlist' && shortlistCount ? (
                  <>({shortlistCount})</>
                ) : null}
              </button>
            </li>
          ))}
      </ul>
    </div>
    <div className="absolute bottom-0 mt-4 w-full p-4">
      <div className="flex justify-between border-t border-[#808080] pt-4 text-subHeading">
        {menuItems
          .filter(
            (item: MenuItemInterface) =>
              item.active && item.type === 'footer-menu'
          )
          .map((item: MenuItemInterface) => (
            <button
              key={item.key}
              type="button"
              onClick={() => handleClick(item)}
              className={`font-medium text-[#808080] ${
                activeRoute === item.route ? 'underline' : ''
              }`}
            >
              {item.label}
            </button>
          ))}
      </div>
    </div>
  </div>
)

export default LeftNavigation
