import { Location } from 'history'
import React from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { isNotSessionSettingsOrTools } from '@utilities/helper'

import {
  FastForwardSvg,
  PauseSvg,
  PlaySvg,
  RewindSvg,
  StopSvg,
} from '@svg/react'

export interface ComponentPropsInterface {
  galleryName: string
  playerSession: PlayerControlInterface | undefined
  projectIdentity: ProjectIdentity
  className?: string
  showSeekControls?: boolean
}

const ScrubBar = ({
  galleryName,
  playerSession,
  projectIdentity,
  className,
  showSeekControls,
}: ComponentPropsInterface) => {
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [videoLengthInMilliseconds, setVideoLengthInMilliseconds] =
    React.useState(0)
  const [playerState, setPlayerState] = React.useState('')
  const [isBuffering, setBufferStatus] = React.useState(false)
  const [isSeeking, setIsSeeking] = React.useState(false)
  const [
    currentVideoPositionInMilliseconds,
    setCurrentVideoPositionInMilliseconds,
  ] = React.useState(-1)

  const [rangeValue, setRangeValue] = React.useState(0)
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const videoPlayerStateRef = React.useRef<string>()
  const rangeValueRef = React.useRef<number>(0)

  const updateScrubPositionInFirebase = async (value: number) => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.scrubPositionInSeconds`]: value,
    })
  }

  const updateCurrentVideoPositionInSecondsInFirebase = async (
    value: number
  ) => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.currentVideoPositionInSeconds`]:
        value / 1000,
    })
  }

  const resetCurrentVideoAndScrubPositionInSecondsInFirebase = async () => {
    setCurrentVideoPositionInMilliseconds(-1)
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.currentVideoPositionInSeconds`]: -1,
      [`${galleryName}.playerControl.scrubPositionInSeconds`]: -1,
    })
  }

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(undefined)
    }
  }

  const setTimer = () => {
    if (!intervalId) {
      setIntervalId(
        setInterval(() => {
          setRangeValue((previousValue) => {
            const shouldVideoLoop =
              videoLengthInMilliseconds > 0 &&
              previousValue >= videoLengthInMilliseconds &&
              playerState === 'pause'
            const value = shouldVideoLoop ? 0 : previousValue + 250
            rangeValueRef.current = value
            return value
          })
        }, 250)
      )
    }
  }

  const handleOnChange = (valueInMilliseconds: number) => {
    resetTimer()
    setRangeValue(valueInMilliseconds)
    if (videoPlayerStateRef.current === 'pause') {
      rangeValueRef.current = valueInMilliseconds
    }
  }

  const handleOnChangeComplete = async (valueInMilliseconds: number) => {
    await updateScrubPositionInFirebase(valueInMilliseconds / 1000)
  }

  const handleStopClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'stop',
      [`${galleryName}.playerControl.isPlayerVisible`]: false,
    })
    resetCurrentVideoAndScrubPositionInSecondsInFirebase()
  }

  const handlePlayClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'play',
    })
    updateScrubPositionInFirebase(rangeValue / 1000)
  }

  const handlePauseClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'pause',
    })
  }

  const handleFastForward = async () => {
    if (isSeeking) return

    setIsSeeking(true)

    if (rangeValue + (15 + 1) * 1000 > videoLengthInMilliseconds) {
      await handleOnChangeComplete(videoLengthInMilliseconds)
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.playerState`]: 'pause',
      })
      handleOnChange(videoLengthInMilliseconds)
    } else {
      await handleOnChangeComplete(rangeValue + 15 * 1000)
      handleOnChange(rangeValue + 15 * 1000)
    }

    setIsSeeking(false)
  }

  const handleRewind = async () => {
    if (isSeeking) return

    setIsSeeking(true)

    await handleOnChangeComplete(Math.max(rangeValue - 15 * 1000, 0))
    if (rangeValue - 15 * 1000 <= 0) {
      await firebaseControlQuery.updateCollection(
        `${galleryName}.playerControl.playerState`,
        'pause'
      )
    }
    handleOnChange(Math.max(rangeValue - 15 * 1000, 0))

    setIsSeeking(false)
  }

  React.useEffect(() => {
    if (playerState === 'pause') {
      resetTimer()
    }

    if (playerState === 'stop') {
      resetTimer()
      resetCurrentVideoAndScrubPositionInSecondsInFirebase()
    }
  }, [playerState, rangeValue])

  React.useEffect(() => {
    if (isBuffering) {
      resetTimer()
    }
  }, [isBuffering])

  React.useEffect(() => {
    if (currentVideoPositionInMilliseconds >= 0) {
      setRangeValue(currentVideoPositionInMilliseconds)
      rangeValueRef.current = currentVideoPositionInMilliseconds
    }
  }, [currentVideoPositionInMilliseconds])

  React.useEffect(() => {
    if (playerState === 'play' && !isBuffering && !intervalId) {
      setTimer()
    }
  }, [currentVideoPositionInMilliseconds, playerState, isBuffering])

  React.useEffect(() => {
    if (rangeValue > videoLengthInMilliseconds) {
      setRangeValue(0)
    }
  }, [rangeValue])

  React.useEffect(() => {
    if (playerSession) {
      const {
        playerState: playerStateFirebase,
        videoLengthInSeconds: videoLengthInSecondsFirebase,
        isBuffering: isBufferingFirebase,
        currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
        videoSynchronizeTriggerKey: videoSynchronizeTriggerKeyInFirebase,
      } = playerSession

      if (videoSynchronizeTriggerKeyInFirebase) {
        if (galleryName === 'unitGallery') {
          updateScrubPositionInFirebase(rangeValueRef.current / 1000)
        }
        if (galleryName === 'videoGallery') {
          updateCurrentVideoPositionInSecondsInFirebase(rangeValueRef.current)
        }

        firebaseControlQuery.update({
          [`${galleryName}.playerControl.videoSynchronizeTriggerKey`]: false,
        })
      }

      setPlayerState(playerStateFirebase)
      videoPlayerStateRef.current = playerStateFirebase

      setBufferStatus(isBufferingFirebase)
      setVideoLengthInMilliseconds(videoLengthInSecondsFirebase * 1000)
      setCurrentVideoPositionInMilliseconds(
        currentVideoPositionInSecondsFirebase * 1000
      )
    }
  }, [
    playerSession?.playerState,
    playerSession?.videoLengthInSeconds,
    playerSession?.isBuffering,
    playerSession?.currentVideoPositionInSeconds,
    playerSession?.videoSynchronizeTriggerKey,
  ])

  React.useEffect(() => {
    const updateVideoPositionOnUnmount = () => {
      if (
        videoPlayerStateRef.current === 'pause' ||
        videoPlayerStateRef.current === 'play'
      ) {
        updateCurrentVideoPositionInSecondsInFirebase(
          rangeValueRef.current
        ).catch((error) => console.error(error))
      }
    }

    const handleRouteChange = (location: Location) => {
      const { pathname } = location
      if (isNotSessionSettingsOrTools(pathname)) {
        handleStopClick().catch((error) => console.error(error))
      }
    }

    const handleBeforeUnload = () => {
      if (
        videoPlayerStateRef.current === 'pause' ||
        videoPlayerStateRef.current === 'play'
      ) {
        updateCurrentVideoPositionInSecondsInFirebase(
          rangeValueRef.current
        ).catch((error) => console.error(error))
      }
    }

    const unsubscribeHistory = history.listen(handleRouteChange)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      updateVideoPositionOnUnmount()
      unsubscribeHistory()
      window.removeEventListener('beforeunload', handleBeforeUnload)
      clearInterval(intervalId)
    }
  }, [history])

  return (
    <>
      <div className={`flex items-center gap-2 ${className}`}>
        {playerState === 'pause' ? (
          <button type="button" onClick={() => handlePlayClick()}>
            <PlaySvg className="h-5 w-5 cursor-pointer text-mainColour" />
          </button>
        ) : (
          <button type="button" onClick={() => handlePauseClick()}>
            <PauseSvg
              className="h-5 w-5 cursor-pointer text-mainColour"
              stroke={1.2}
            />
          </button>
        )}

        <button type="button" onClick={() => handleStopClick()}>
          <StopSvg
            className="h-9 w-9 cursor-pointer text-mainColour"
            stroke={1.2}
          />
        </button>

        {showSeekControls && (
          <>
            <button type="button" onClick={() => handleRewind()}>
              <RewindSvg className="h-6 w-6 cursor-pointer text-mainColour" />
            </button>

            <button type="button" onClick={() => handleFastForward()}>
              <FastForwardSvg className="h-6 w-6 cursor-pointer text-mainColour" />
            </button>
          </>
        )}

        <div className="w-full px-3">
          <InputRange
            maxValue={videoLengthInMilliseconds}
            minValue={0}
            value={rangeValue}
            onChange={(value) => handleOnChange(Number(value))}
            onChangeComplete={(value) => handleOnChangeComplete(Number(value))}
            formatLabel={() => ''}
          />
        </div>
      </div>
    </>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(ScrubBar)
