import React from 'react'

import { PropertyOptionType } from '@api/houseAndLand'

import AssetHandler from '@utilities/asset-handler'
import { formatPrice } from '@utilities/helper'

type OptionalFields = 'price' | 'images' | 'thumbnail'

export type Option = Omit<PropertyOptionType, OptionalFields> &
  Partial<Pick<PropertyOptionType, OptionalFields>>

interface PropertyOptionProps {
  isSelected: boolean
  option: Option
  onClick: () => void
  showAsThumbnail: boolean
}

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

const PropertyOption = ({
  isSelected,
  option,
  onClick,
  showAsThumbnail,
}: PropertyOptionProps) =>
  showAsThumbnail ? (
    <div className="cursor-pointer" onClick={onClick} role="none">
      <div
        className={`relative h-[80px] overflow-hidden ipad-mini-land:h-[100px] ${
          isSelected ? 'rounded-t' : 'rounded'
        }`}
      >
        <div className="absolute right-2 top-2 flex h-[20px] w-[20px] items-center justify-center rounded-md bg-white">
          <div
            className={`h-[10px] w-[10px] rounded transition-colors duration-300 ${
              isSelected ? 'bg-gradient shadow-standard' : 'bg-white'
            }`}
          />
        </div>

        <img
          className="h-full w-full object-cover"
          alt="thumbnail"
          src={AssetHandler({
            url: String(
              option?.thumbnail?.url ||
                option.images?.[0]?.url ||
                PLACEHOLDER_IMAGE
            ),
            type: 'new',
            staticUrl: true,
          })}
        />
      </div>

      <div
        className={`flex justify-between gap-2 rounded-b px-2 py-1 transition-colors duration-300 ${
          isSelected ? 'bg-gradient shadow-standard' : 'bg-transparent'
        }`}
      >
        <p
          className={`truncate text-xl ${
            isSelected ? 'text-white' : 'text-mainColour'
          }`}
        >
          {option.name}
        </p>
        {option.price ? (
          <p
            className={`text-base leading-7 ${
              isSelected ? 'text-white' : 'text-[#808080]'
            }`}
          >
            +{formatPrice(option.price)}
          </p>
        ) : null}
      </div>
    </div>
  ) : (
    <div
      className={`col-span-2 cursor-pointer px-4 py-3.5 transition-all duration-300 ipad-mini-land:px-6 ${
        isSelected ? 'bg-gradient shadow-standard' : 'bg-transparent'
      }`}
      onClick={onClick}
      role="none"
    >
      <div
        className={`flex items-center justify-between gap-2 pr-2 ${
          isSelected ? 'text-white' : ''
        } font-medium`}
      >
        <span
          className={`truncate text-xl font-medium ${
            isSelected ? 'text-white' : 'text-mainColour'
          }`}
        >
          {option.name}
        </span>

        <div className="flex items-center gap-3">
          {option.price ? (
            <span
              className={`text-base ${
                isSelected ? 'text-white' : 'text-mainColour'
              }`}
            >
              +{formatPrice(option.price)}
            </span>
          ) : null}
          <div className="flex h-[25px] w-[25px] items-center justify-center rounded-md bg-white">
            <div
              className={`h-[13px] w-[13px] rounded transition-colors duration-300 ${
                isSelected ? 'bg-gradient' : 'bg-white'
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  )

export default PropertyOption
