import { MenuItemInterface } from '@store/types'

const MENU_ITEMS: Array<MenuItemInterface> = [
  {
    key: 'residences',
    label: 'Residences',
    route: 'area-view',
    relatedRoutes: [
      'area-view',
      'level-view',
      'building',
      'snaploader-view',
      '3d-building',
      'panoramic-view',
      'panoramic',
      'area-view-house-and-land',
      'precinct',
      'stages',
      'lot-view',
    ],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 1,
  },
  {
    key: 'interactive-map',
    label: 'Map',
    route: 'interactive-map',
    relatedRoutes: ['interactive-map'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 2,
  },
  {
    key: 'videos',
    label: 'Videos',
    route: 'video-gallery',
    relatedRoutes: ['video-gallery'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 3,
  },
  {
    key: 'gallery',
    label: 'Gallery',
    route: 'gallery',
    relatedRoutes: ['gallery'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 4,
  },
  {
    key: 'brochure',
    label: 'Brochure',
    route: 'brochure',
    relatedRoutes: ['brochure'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 5,
  },
  {
    key: 'team',
    label: 'Team',
    route: 'teams',
    relatedRoutes: ['teams'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 6,
  },
  {
    key: 'external-routes',
    label: 'External links',
    route: 'external-views',
    relatedRoutes: ['external-views'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 7,
  },
  {
    key: 'shortlist',
    label: 'Shortlist',
    route: 'shortlist',
    relatedRoutes: ['shortlist'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 8,
  },
  {
    key: 'session-settings',
    label: 'Session',
    route: 'session-settings',
    relatedRoutes: ['session-settings'],
    active: true,
    type: 'footer-menu',
    routeViaFirebase: false,
    order: 9,
  },
  {
    key: 'tools',
    label: 'Tools',
    route: 'tools',
    relatedRoutes: ['tools'],
    active: true,
    type: 'footer-menu',
    routeViaFirebase: false,
    order: 10,
  },
]

export default MENU_ITEMS
