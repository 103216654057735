import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import InteractiveMapControl from '@components/interactive-map-control'

import {
  selectFromResult as selectFromInterctiveMapResult,
  useGetInteractiveMapQuery,
} from '@api/interactive-map'

import { getSession } from '@utilities/firebase-util'

import InteractiveMapSkeleton from './interactive-map-skeleton'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectName: string
}

const InteractiveMap = ({ session, projectName }: PagePropsInterface) => {
  const interactiveMapPayload = useGetInteractiveMapQuery(
    { projectName },
    { selectFromResult: selectFromInterctiveMapResult }
  )

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactiveMapPayload,
          data: interactiveMapPayload.interactiveMapData,
        }}
        skeletonFrame={<InteractiveMapSkeleton />}
      >
        <div className="h-full w-full">
          <div className="relative flex h-top-bar w-full items-center justify-center px-5 text-neutralColour">
            <div className="text-title font-medium">Map</div>
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <InteractiveMapControl
              interactiveMapList={interactiveMapPayload.interactiveMapData}
              interactiveMapSession={session?.interactiveMap ?? []}
              interactiveMapActiveCategory={
                session?.interactiveMapActiveCategory
              }
              storyMode={interactiveMapPayload.storyMode}
            />
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectName: projectIdentity.projectId,
}))(InteractiveMap)
